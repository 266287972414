@import '../../styles/common/colors';

.products-container {
  max-width: 90%;
  margin: 64px auto;
  justify-content: space-between;
}
.product-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 70px;
    height: 70px;
    color: $info500;
  }
}

.lp_transfer--btn {
  display: flex;
  justify-content: flex-end;
}
