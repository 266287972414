@import '../../styles/common/colors';

.per-page-container {
  .MuiFormControl-root {
    min-width: 130px;
  }
  .MuiInputLabel-outlined {
    background-color: transparent !important;
  }
}
