@import '../../../styles/common/mixins';

.not-found-container {
  width: 90%;
  margin: auto;
}

@include forTabletPortraitUp() {
  .not-found-container {
    width: 40%;
    margin: auto;
  }
}
