@import '../../styles/common/colors';

.signature-container {
  svg {
    width: 24px;
    height: 24px;
    color: $success600;
  }
  &-error {
    svg {
      color: $error500;
    }
  }
}
