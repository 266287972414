@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/shadows";
@import "../../styles/common/card";

.partner-view-container{
  padding: 32px;
}

.partner-view-element-container{
  h3{
    color: $primary500 !important;
  }
}
.partner-vc-content{
  font-family: "Inter", sans-serif;
  color: $primary500 !important;
}
.dashboard-back{
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: $lightGrey;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 24px;
  width: 100%;
}


@include forPhoneOnly{
   h3{
    font-size: 1.4rem;
  }
  .partner-view-container{
    padding: 16px;
  }
}


