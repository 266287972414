@import "../../styles/common/mixins";
@import "../../styles/common/colors";

.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otp-input-container {
  flex-wrap: wrap;
}

.otp-text {
  color: $grey700;
  font-weight: 400;
}

.otp-phone-number {
  color: $primary500;
  font-weight: 700;
  letter-spacing: 1px;
}

.otp-submit-container {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.otp-new {
  color: $primary500;
  text-decoration: underline;
  cursor: pointer;
}

.otp-new-disabled {
  color: $disabled;
}

@include forTabletLandscapeUp {
  .otp-input-container {
    flex-wrap: nowrap;
  }
}
