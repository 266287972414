@import "../../styles/common/colors";
@import "../../styles/common/card";
@import "../../styles/common/mixins";
@import "../../pages/auth/signup/style";

.important-horiz {
  color: $horizMain;
  font-weight: 600;
}

@include forTabletLandscapeUp {
  .horiz-container {
    padding-top: 24px;
  }
}

@include forDesktopUp {
  .collab-top {
    top: 120px;
  }
}
