@mixin z1 {
  box-shadow: 0 1px 2px rgba(145, 158, 171, 0.16);
}

@mixin z8 {
  box-shadow: 08px 16px rgba(145, 158, 171, 0.16);
}

@mixin z12 {
  box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.16);
}

@mixin z16 {
  box-shadow: 0 16px 32px -4px rgba(145, 158, 171, 0.16);
}

@mixin z20 {
  box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.16);
}

@mixin z24 {
  box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.1);
}

@mixin cardShadow {
  border: 1.2px solid #E4E7EC;
}

@mixin dialogShadow {
  box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24);
}

@mixin dropdownShadow {
  box-shadow: 0 0 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
}

