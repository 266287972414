@import '../../styles/common/_card.scss';
@import '../../styles/common/_mixins.scss';

details {
  min-height: 5px;
  padding: 16px;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
  box-sizing: border-box;
  transition: all .3s;
  p {
    line-height: 2.0;
  }
}

details + details {
  margin-top: 8px;
}

summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

summary:focus {
  outline: none;

}

summary:focus::after {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  @include z16;
  border: none;
  border-radius: 16px;
}

summary::-webkit-details-marker {
  display: none
}

.control-icon {
  fill: rebeccapurple;
  transition: .3s ease;
  pointer-events: none;
}

.control-icon-close {
  display: none;
}

details[open] .control-icon-close {
  display: initial;
  transition: .3s ease;
}

details[open] .control-icon-expand {
  display: none;
}


@include forDesktopUp {
  summary {
    font-size: 14px;
  }
}
