@import "../../../styles/common/mixins";
@import "../../../styles/common/colors";

.livretp-banner {
   height: 50px;
   width: 94.5%;
   margin: 16px 16px -8px 16px;
   padding: 16px 8px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-radius: 2px;
   &__column {
      flex-direction: column;
      margin: 0;
      width: 97%;
      height: fit-content;
      align-content: center;
      gap: 16px;
      &-left-content {
         text-align: center;
         width: 80%;
      }
   }
   &__blue {
      border: 1px solid $blue;
      & > * {
         color: $blue;
      }
   }
   &__yellow {
      border: 1px solid $warning500;
   }
   &-left-content {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
   }
   &-icon {
      &__yellow {
         color: $warning500;
         margin-top: 5px;
      }
   }
}

.livretp_banner-link {
   text-decoration: none;
   text-align: center;
   font-size: 16px;
   max-width: 70%;
   color: $blue;
   &__yellow {
      color: $warning700;
   }
}
.livretp_banner-link-btn {
   text-decoration: none;
   padding: 4px 10px;
   border-radius: 2px;
   color: $blue;
   background: $blueLightGradient !important;
   font-weight: 700;
   &__yellow {
      color: $warning700;
      background: $warningLightGradient !important;
   }
}

@include forPhoneOnly {
   .livretp_banner-link {
      text-align: left;
      font-size: 12px;
      max-width: 80%;
   }
   .livretp-banner {
      margin: 16px 16px 0 16px;
      width: 91%;
      height: 70px;
      &__link {
         font-size: 12px;
      }
   }
}
