@import "../../../styles/common/mixins";
@import "../../../styles/common/card";

.my-documents-container {
  padding: 0 8px;
  margin-top: 16px;
  .MuiTableCell-root {
    border-bottom: 1px solid $grey300 !important;
  }
}

.documents-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  & > * {
    width: 100%;
  }
}

.see-more {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.doc-badges {
  margin-bottom: 16px;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: flex-start;
}

@include forTabletPortraitUp {
  .my-documents-container {
    padding: 0 16px 0 32px;
  }

  .documents-container {
    & > * {
      width: 48%;
    }
  }
  .doc-badges {
    margin-bottom: 0;
    align-items: center;
  }
}

@include forTabletLandscapeUp {
  .documents-container {
    gap: 24px;
    & > * {
      width: 30.8%;
    }
  }
}

@include forDesktopUp {
  .my-documents-container {
    padding: 0 32px;
  }
  .documents-container {
    & > * {
      width: 31.5%;
    }
  }
}
