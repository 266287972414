@import "../../../styles/common/colors";
@import "../../../styles/common/buttons";

.colored-card-container {
  border-radius: 2px;
  width: 100%;
}

.colored-card-user-infos {
  display: block;
}

.colored-card-container-img {
  border-radius: 2px;
  width: 100%;
  margin: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to right bottom, #08262a, #0f2f33, #17383d, #1e4247, #264c51);
}

.colored-card-infos {
  flex-direction: column !important;
  gap: 24px;
}

.colored-card-keyinfos-container {
  background-color: rgba($white, 0.9);
  border: 1px solid rgba($grey500, 0.32);
  color: $white;
  border-radius: 2px;
}

.investment-rate {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: $white;
  span {
    color: $grey400;
  }
}

.colored-card-buttons {
  border-top: 1px solid $grey500;
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
  .btn-invest {
    @include btnSecondary($primary500, $primary600);
    color: $primary500;
  }
  .btn-go-investments {
    @include btnSecondaryColored($warning700, $grey400);
    color: $warning700;
  }
}
