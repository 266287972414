@import "../../../styles/common/colors";
@import "../../../styles/common/mixins";
@import "../../../styles/common/shadows";

.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root {
  &:hover {
    width: 90%;
    margin: auto;
    border-radius: 5px;
  }
  &:active {
    background-color: $primary500;
  }
}

.links-container {
  position: relative;
  padding: 8px;
  gap: 8px;
  &__link {
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    color: $grey700;
    border-radius: 2px 2px 2px 0;
    padding: 6px 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    svg {
      width: 14px !important;
      height: 14px !important;
    }
    &:hover {
      color: $primary500;
      border-right: 3px solid $primary400;
      background-color: rgba($primary500, 0.10);
      svg {
        color: $primary500;
      }
    }
    &__active {
      border-right: 3px solid $primary400;
      background-color: rgba($primary500, 0.10);
      color: $primary500;
      font-weight: 700;
      svg {
        color: $primary500;
      }
    }
  }
}

.no-style-link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
    color: #007bff;
  }
}

.drawer-link {
  font-size: 11px;
  font-weight: 600;
  text-decoration: none;
  color: $grey800;
}

.drawer-section-title {
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  color: $grey600;
  font-size: 10px;
  text-decoration: none;
  margin-left: 4px;
}

.drawer {
  width: 180px !important;
  z-index: 10 !important;
}

.MuiDrawer-paper {
  width: 180px !important;
  z-index: 10 !important;
  overflow: visible !important;
  background-color: $white !important;
  .auth-os-logo {
    margin-bottom: 0 !important;
  }
}

.soon-container {
  margin-top: 8px;
  position: relative;
  border: 1px solid rgba($info500, 0.16);
  padding: 6px 8px;
  border-radius: 2px;
  gap: 8px;
  p {
    color: $info600;
  }
}

.loginfo-container {
  display: flex;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  margin-bottom: 16px;
  position: relative;
  background-color: $info100;
  border-radius: 2px;
  word-wrap: break-word;
  white-space: normal;
  width: 100%;
  p {
    color: $info600;
    text-align: center;
  }
}

.bottom-drawer {
  position: absolute;
  bottom: 24px;
  left: 8px;
  width: 90%;
}

.nav-item-img {
  width: 30px;
}

@include forDesktopUp {
  .drawer {
    width: 200px !important;
  }
  .MuiDrawer-paper {
    width: 200px !important;
  }
  .links-container {
    gap: 12px;
    &__link {
      width: 92%;
      gap: 8px;
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
  .drawer-section-title {
    font-size: 12px;
  }
}
