@import "../../../../styles/common/colors";
@import "../../../../styles/common/mixins";

.product-documents-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

.product-documents-container-synthesis {
  display: none;
}

.product-document {
  padding: 12px;
  border: 1px solid $grey400;
  border-radius: 2px;
  min-height: 70px;
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  width: 100%;
  overflow: hidden;
  svg {
    flex-shrink: 0;
  }
  &-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-name {
    color: $grey700;
    font-weight: 500;
    word-break: break-word;
    font-size: 13px;
    margin: 0;
  }
  &-download {
    color: $primary500;
    font-size: 12px;
    margin: 0;
  }
  &:hover {
    transition: 0.4s;
    background-color: $secondary300;
    border: 1px solid $primary500;
  }
}

.product-document-name-container {
  align-items: center;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
  svg {
    color: $primary500;
  }
}

.finalise-product-document-name {
  max-width: 220px;
}
@include forTabletPortraitUp {
  .product-document {
    width: 48%;
  }
}
@include forDesktopUp {
  .product-document {
    width: 31%;
  }
  .product-documents-container-synthesis {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 30%;
    & > * {
      width: 100% !important;
    }
  }
}
