@import '../../styles/common/colors';
@import '../../styles/common/mixins';

.os-badge {
  background-color: rgba($primary600, 0.16);
  color: $primary600;
  font-weight: 600 !important;
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;
  font-size: 11px;
  &__success {
    background-color: rgba($success100, 0.8);
    color: $success700;
    &__full {
      background-color: $success100;
    }
  }
  &__error {
    background-color: rgba($error100, 0.5);
    color: $error700;
  }
  &__warning {
    background-color: rgba($warning100, 0.5);
    color: $warning600;
  }
  &__info {
    background-color: rgba($info100, 0.5);
    color: $info700;
  }
  &__grey {
    background-color: rgba($grey100, 0.5);
    color: $grey700;
  }
  &__dark-gradient {
    background: $primary500;
    color: $grey100;
  }
  &__white {
    background-color: $white;
    color: $primary500;
  }
}
