@import '../../styles/common/mixins';

.empty-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .svg-illustration {
    svg {
      min-width: 80px;
      min-height: 80px;
      max-width: 150px;
      max-height: 150px;
      color: $secondary400;
    }
  }
  .empty-content-btn {
    width: auto;
  }
  .engagement_btns {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.empty-content-btns {
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}
