@import "../../styles/common/colors";

.my-text-input {
  position: relative;
  input {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 6px;
    padding: 14px;
    background-color: transparent;
    border: 1px solid #DCE0E4;
    width: 100%;
    outline: none;
    transition: box-shadow ease .25s,
    border-color ease .25s;
  }
  input:focus {
    outline:none;
  }
  &__clicked {
    border: 1px solid $error500 !important;
    border-radius: 6px;
    transition: box-shadow ease .25s,
    border-color ease .25s;
  }

  /* LABEL ======================================= */
  label {
    color: $primary400;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(14px, 16px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
  }

  &__clicked-label {
    color: $error500 !important;
  }
}

input:focus ~ label, input:valid ~ label {
  top:-25px;
  font-size: 14px !important;
  background-color: transparent;
}

