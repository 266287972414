@import '../../../styles/common/colors';
@import '../../../styles/common/mixins';


.verification-page {
  background: rgb(10,38,42);
  background: -moz-linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  background: linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a262a",endColorstr="#236575",GradientType=1);
  color: $grey100;
  padding-bottom: 100px;
  h3 {
    color: $grey100;
  }
  svg {
    width: 64px;
    height: 64px;
  }
}

.verification-content {
  color: $grey100;
}

.cgp-uploads-container {
  flex-wrap: wrap;
  & > * {
    width: 100%;
  }
}
.ressources-signup {
  & > * {
    width: 100%;
  }
  margin-bottom: 32px;
}

.ressources-signup {
  width: 100% ;
  border-radius: 4px;
}


@include forTabletPortraitUp {
  .verification-page {
    & > * {
      margin: auto;
      max-width: 70%;
    }
  }
}

@include forTabletLandscapeUp {
  .verification-page {
    & > * {
      max-width: 50%;
    }
  }
}
