@import "../../styles/common/colors";
@import "../../styles/common/card";
@import "../../styles/common/mixins";
@import "../../styles/common/buttons";

.encompasses-container{
  @include card;
  margin: auto;
  height: 90%;
  input {
    width: 50%;
  }
  .title-container{
    background-repeat: no-repeat;
    background-size: 900px;
    background-position: center;
    border-radius: 16px 16px 0 0;
    height: 210px;
    width: 100%;
    margin-bottom: 10px;
    h3{
      color: $grey100 ;
      padding: 81px 70px;
    }
  }

  .content-containers {
    padding: 24px;
  }
}

.os-card {
  @include card;
  padding: 16px 24px;
  height: auto;
  align-self: stretch;
  &__link {
    color: $primary500;
    text-decoration: none;
    font-size: 13px;
    font-weight: bold;
  }
  &--bg {
    &-green {
      background-color: $webflowGreen;
    }
    &-grey {
      background-color: rgba($grey200, 0.10);
    }
  }
  &--shadows {
    &-no-shadow {
      box-shadow: none !important;
      border: none !important;
    }
  }
  &--spaced {
    &-up {
      margin-top: 24px;
    }
    &-down {
      margin-bottom: 24px;
    }
  }
  p {
    color: $grey600;
  }
}

.os-card--children-menu {
  align-items: flex-start;
  border-bottom: 1px solid $grey400;
}

.os-card-btns {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.badges-container {
  align-items: flex-start;
}

@include forTabletPortraitUp {
  .badges-container {
    flex-direction: row !important;
  }
  .os-card-title-container {
    flex-direction: row !important;
    align-items: center;
  }
}

@include forTabletLandscapeUp {
  .encompasses-container  {
    padding: 0 !important;
    width: 70%;
    margin: 30px auto 30px auto;
  }
  .os-card--children-menu {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;
  }
}

@include forDesktopUp {
  .encompasses-container  {
    width: 50%;
  }

  .btn-container{
    width: 40%;
  }
}
