@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/shadows";
@import "../../styles/common/card";
@import "../../styles/common/buttons";

.partner-vc-container {
  background: #FFFFFF;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 64px;
}
.partner-vc-left {
  width: 100%;
  border-radius: 16px;
  height: 500px;
}

.partner-vc-card{
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-left: 16px;
}

.partner-vc-infos {
  width: 100%;
}

.partner-vc-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $warningMainRgba;
  margin: 0 0 16px 0;
}
.partner-vc-link-container{
  display: flex;
  gap: 5px;
  align-items: center
}
.partner-vc-link {
  color: $primary500;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.partner-vc-card-bottom-container {
  width: 30%;
  margin: 0 auto 64px auto;
}

.partner-vc-card-logo {
  width: 100%;
  border-radius: 10px;
}

@include forTabletLandscapeUp{
  .partner-vc-left {
    width: 40%;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    height: 100vh;
  }
  .partner-vc-container {
    flex-direction: row;
  }
  .partner-vc-infos {
    width: 50%;
  }

  .partner-vc-title {
    margin: 32px 0;
  }
}

@include forPhoneOnly{
  h2{
    font-size: 18px;
  }
  .partner-vc-container{
    padding: 15px;
  }
  .partner-vc-link{
    font-size: 13px;
  }

}
