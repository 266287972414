@import "../../styles/common/colors";
@import "../../styles/common/buttons";
@import "../../styles/common/mixins";
@import "../../styles/common/shadows";
@import "../../styles/common/card";

.livretp-page-container {
  overflow-x: hidden;
}
.livretp-page {
  padding: 0 16px 16px 16px;
}

.livretp-container {
  display: block;
}

.livretp-white-container {
  background-color: $secondary300;
  border-radius: 10px;
  padding-bottom: 1px;
  width: 100%;
}

.livretp-user-infos {
  display: block;
}

.livretp-container-img {
  border-radius: 10px;
  width: 100%;
  margin: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to right bottom, #08262a, #0f2f33, #17383d, #1e4247, #264c51);
  p {
    color: $white;
  }
}

.boost-text {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 13px;
  color: $navy;
  font-weight: 600;
  &__spaced {
    justify-content: space-between;
    padding-right: 10px;
  }
  &__link {
    text-decoration: none;
    color: $primary500;
  }
}

.page-boost-text {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-size: 13px;
  color: #133251;
  font-weight: 600;
  background-color: $secondary400;
  border-radius: 5px;
  align-items: center;
  justify-content: space-around;
  padding: 0 8px;
  p {
    color: $primary500;
    margin-left: 5px;
  }
}
.page-boost-date {
  font-weight: 600;
}


.livretp-btns {
  background: $primaryLightGradient;
  padding: 16px;
  margin: 20px 0;
  flex-wrap: wrap;
  border-radius: 10px;
}

.deposit {
  @include btnSmallMainColored($primary500, $primary600);
  border-radius: 50px;
  box-shadow: 0 1px 3px #538A92;
}

.withdraw {
  @include btnSmallSecondaryColored($navy, $grey400);
  border-radius: 50px;
}

.boosts {
  @include btnSmallMainColored($warning500, $warning600);
  border-radius: 50px;
  box-shadow: 0 1px 3px $warning500;
}

.livretp-infos {
  p {
    margin: 5px 0;
  }
  img {
    width: 90px;
    margin-bottom: 8px;
  }
}

.livretp-card {
  @include card;
  height: auto;
  @include cardShadow;
}

.referral-card {
  width: 100%;
}

.card-infos-interests {
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid $grey300;
  border-radius: 16px;
  padding: 16px;
  h5{
    margin: 12px 0 0 0;
    color: $grey600;
    font-weight: 500;
  }
  span {
    font-size: 13px;
    color: $grey600;
  }
  &__more {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.livretp-interests-divider {
  border-top: 1px solid $grey600;
  margin-top: 5px;
  opacity: 0.2;
}

.transactions-top {
  padding: 0 16px;
  p {
    color: $navy;
    font-weight: 600;
    font-size: 15px;
  }
  span {
    color: $primary500;
    font-size: 14px;
    cursor: pointer;
  }
}

.transactions-labels {
  background-color: $grey200;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 13px;
    color: $grey600;
    font-weight: 600;
  }
}

.livretp-content {
  font-size: 14px;
  color: $grey200;
  opacity: 0.8;
  display: flex;
  align-items: center;
}

.euro-balance {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  color: $white;
  margin: 0;
}

.base-interest {
  font-size: 13px;
  flex-direction: column !important;
  align-items: center;
  color: $webflowGreen;
}

.base-interest-title {
  opacity: 0.8;
  color: $webflowGreen;
  margin: 0;
  font-size: 13px;
}
.base-interest-percentage {
  color: $webflowGreen;
  font-size: 18px;
  font-weight: 700;
}
.user-earned-interests {
  margin-left: 6px;
  margin-right: 6px;
  color: $webflowGreen;
  font-size: 16px;
  font-weight: 700;
}

.inbound-content {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 23px;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
}

.colored-card-infos-container {
  align-items: center;
  justify-content: space-around;
  padding: 8px 16px;
}

.content-card {
  flex: 0 0 auto;
  text-decoration: none;
  color: $white;
  background-color: $white;
  height: 150px;
  width: 300px;
  border-radius: 10px;
  margin-top: 20px;
  padding: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
}

.content-card__img {
  width: 70%;
  height: 145px;
}

.content2 {
  background-color: $navy;
  padding: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}

.invite-cta {
  background-color: $white;
  color: $primary500;
  padding: 7px 21px;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 10px;
  width: 15%;
  display: flex;
  align-items: center;
}

.content-card-infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-card-text {
  margin: 5px 0;
}

.image-card {
  background-repeat: no-repeat;
  background-size: cover;
}

.image-card-content {
  width: 80%;
  p {
    font-size: 15px;
    font-weight: 600;
  }
  span {
    font-size: 13px;
    display: inline-block;
    margin-bottom: 5px;
  }
  a {
    font-size: 14px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
}
.boost-cta {
  background-color: $white;
  color: $primary500;
  padding: 7px 14px;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 10px;
  width: 32%;
  display: flex;
  align-items: center;
}

.interest-fab {
  @include fabNoBorder($primary500);
  cursor: pointer;
}

.simplebar-placeholder {
  display: none !important;
}

.livretp-bottom-container {
  display: block;
}

.referral-content {
  width: 90%;
  margin: auto;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.referral-outline {
  span {
    color: $primary500;
    font-weight: bold;
    white-space: pre-line;
  }
}

.slider-livretp {
  display: none;
}

.slider-mobile-livretp {
  display: block;
  height: 300px !important;
}

.see-more-transactions {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  color: $primary500;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
}

@include forTabletPortraitUp {
  .livretp-white-container {
    width: 65%;
  }
  .slider-livretp {
    display: block;
    width: 30%;
  }
  .slider-mobile-livretp {
    display: none;
  }
  .content-card {
    flex-direction: row;
    &__img {
      width: 30%;
      margin-right: 40px;
    }
  }
  .image-card-content {
    span {
      max-width: 75%;
    }
  }

  .livretp-user-infos {
    display: flex;
    gap: 20px;
  }

  .livretp-infos-interests {
    width: 33%;
  }
  .livretp-bottom-container {
    align-items: flex-start;
    justify-content: space-between;
  }

  .referral-card {
    width: 32%;
  }
}

@include forTabletLandscapeUp {
  .livretp-page {
    padding: 32px 16px 16px 16px;
  }
  .livretp-container {
    display: flex;
    justify-content: space-between;
  }
  .livretp-container {
    display: flex;
  }
  .transactions-container {
    width: 66%;
    min-height: 440px;
  }

  .livretp-transactions {
    padding: 16px 32px;
  }

  .content-card-infos {
    width: 70%;
  }

  .content-card__img {
    width: 25%;
    height: 130px;
  }
  .deposit {
    @include btnMainColored($primary500, $primary600);
  }

  .withdraw {
    @include btnSecondaryColored($navy, $grey400);
  }

  .boosts {
    @include btnMainColored($warning500, $warning600);
  }

  .livretp-btns {
    padding: 24px;
    gap: 20px;
  }
}

@include forPhoneOnly{
  .page-boost-text {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    font-size: 11px;
    color: #133251;
    font-weight: 600;
    background-color: $secondary400;
    border-radius: 16px;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    p {
      color: $primary500;
      margin-left: 5px;
    }
    .page-boost-date{
      font-size: 14px;
    }
  }
}
