@import "../../../styles/common/colors";
@import "../../../styles/common/buttons";

.smallSwiper {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  .swiper-slide {
    font-size: 18px;
    border-radius: 18px;
    height: 100%;
    object-fit: cover;
  }

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 25px;
    left: 130px;
    width: 100%;
  }
  span.swiper-pagination-bullet {
    background-color: $warning500 !important;
    opacity: 0.4 !important;
  }

  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $warning500 !important;
    opacity: 1 !important;
  }
}

.slider-btn {
  @include btnMainColored($warning500, $warning600);
  width: 40%;
  font-weight: bold;
}
.slider-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  height: 85%;
  &-label {
    color: $white;
    opacity: 0.7;
    margin: 0;
  }
  &-title {
    color: $white;
    margin: 20px 0;
    max-width: 70%;
    font-size: 16px;
  }
}
