@import "../../../styles/common/colors";
@import "../../../styles/common/mixins";

.cabinets-container {
  margin-top: 0 !important;
}
.cabinets-top-container {
  background-color: $grey200;
  height: 100px;
  padding: 24px 40px 140px 40px;
}

.cabinet-element-container {
  padding: 0 16px;
  margin-top: 24px;
}

.cabinet-tabs-container {
  .MuiTabs-root {
    background-color: transparent !important;
    margin-left: 0 !important;
    .MuiTabs-flexContainer {
      gap: 8px !important;
    }
    button {
      text-transform: capitalize;
      flex-grow: 0 !important;
      -webkit-flex-grow: 0 !important;
      flex-basis: 1px !important;
      -webkit-flex-basis: 1px !important;
      border-bottom: 3px solid transparent;
      min-width: 100px;
      &:hover {
        color: $primary600 !important;
        border-bottom: 3px solid $primary500 !important;
        transition: 0.5s !important;
      }
    }
  }
}
.active-tab {
  color: $primary600 !important;
  border-bottom: 3px solid $primary500 !important;
  transition: 0.5s !important;
}

.members-table {
  margin: 32px 0;
}

.cgp-modal-form {
  margin-top: 24px;
}


@include forTabletLandscapeUp {
  .cabinet-element-container {
    padding: 0 16px;
  }
}
