@import '../../../../styles/common/mixins';

.investment-arrangement-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 16px;
}

.investment-arrangement-left {
  width: 100%;
}

.information-recap {
  width: 100%;
}

.investment-coupon-top {
  justify-content: space-between;
  align-items: center;
}

.investment-card-add-coupon {
  text-decoration: none;
  color: $warning700;
  cursor: pointer;
}

@include forTabletPortraitUp {
  .investment-arrangement-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .investment-arrangement-left {
    width: 68%;
    padding-bottom: 250px;
  }
  .information-recap {
    width: 30%;
  }
}


@include forPhoneOnly {
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin: 0 20px;
  }
}

