@import "../../styles/common/colors";
@import "../../styles/common/mixins";
@import "../../styles/common/card";

.os-footer {
  height: fit-content;
  background-color: black;
  padding: 24px;
  justify-content: space-between;
  p {
    color: $grey300 !important;
  }
  .footer-email {
    color: $grey300 !important;
    text-decoration: none;
    font-weight: 500;
  }
  &-top {
    width: 100%;
    justify-content: space-between;
  }
  &-bottom {
    width: 100%;
    margin: auto;
    border-top: 1px solid $white;
    padding-top: 12px;
    p {
      color: $grey200;
    }
  }
}

.os-footer-logo {
  width: 80px;
}

.legal-title {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.contact-card {
  @include card;
  height: auto;
  p {
    color: $grey600 !important;
  }
  &-img {
    width: 60px;
    border-radius: 50%;
  }
}

.legal-infos {
  display: none;
}

@include forTabletLandscapeUp {
  .os-footer {
    padding: 24px 60px;
    &-left {
      align-items: flex-start;
    }
  }
}
