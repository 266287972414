@import "../../../styles/common/colors";
@import "../../../styles/common/card";
@import "../../../styles/common/mixins";

.collab-container {
  min-height: 100vh;
}

.collab-left-container {
  background: rgb(10,38,42);
  background: -moz-linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  background: linear-gradient(90deg, rgba(10,38,42,1) 0%, rgba(27,61,69,1) 36%, rgba(38,76,81,1) 64%, rgba(35,101,117,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a262a",endColorstr="#236575",GradientType=1);
  display: none;
  width: 100%;
  margin-top: 24px;
}

.collab-title {
  color: $webflowGreen;
}

.already {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 32px;
  margin-left: 2px;
}
.already-link {
  color: $webflowGreen;
  border-bottom: 1px solid $webflowGreen;
  text-decoration: none;
}

.sub-container {
  padding: 16px;
  min-height: 560px;
  h1 {
    font-size: 34px;
    color: $grey700;
  }
}

.collab-auth-container {
  background-color: $secondary100;
  min-height: 600px;
  color: $white;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 8px 150px 8px;
  position: relative;
  width: 95%;
}

.image-section-sign-up {
  background-image: linear-gradient(90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 100%),url('https://imavenir.com/wp-content/uploads/2023/05/facade-immeuble-haussmannien-paris-investissement-locatif.png');
  background-size: cover;
  background-position: center;
  margin-top: 0;
}

.auth-os-logo{
  margin-bottom: 20px !important;
}

.align-right {
  margin-left: auto; /* Pousse le contenu à droite */
}

.os-bw-80{
  word-break: break-word !important;
  width: 80% !important;
}


.avantage-element {
  gap: 16px;
  flex-direction: row !important;
  align-items: center;
  margin: 12px 0;
}

.collab-auth-card {
  color: $grey700;
  height: auto;
  width: 100%;
}

.auth-img-text {
  font-weight: 400;
}

.important {
  color: $white;
  font-weight: 600;
}


@include forTabletPortraitUp {
  .collab-container {
    flex-direction: column-reverse !important;
    justify-content: space-between;
    margin-top: -60px;
  }

  .collab-auth-container {
    min-height: 480px;
  }

  .collab-left-container {
    display: block;
    padding: 16px;
    width: 100%;
    margin-top: 60px;
  }

  .collab-auth-container {
    min-height: 100vh;
    padding: 64px 32px 120px 32px;
    width: 95%;
  }

  .collab-auth-card {
    width: 95%;
  }
}

@include forDesktopUp {
  .collab-container {
    flex-direction: row !important;
  }
  .collab-left-container {
    flex: 1;
    padding: 16px;
    width: 47%;
    margin-top: 60px;
  }
  .collab-auth-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    margin-top: 0;
    min-height: 100vh;
    width: 80%;
    padding: 80px 32px 120px 32px;
  }
  .collab-auth-card {
    width: 100%;
  }
  .top-not-cgp {
  }
  .avantage-element {
    gap: 16px;
    margin: 32px 0;
    img {
      width: 50px;
      height: 50px;
    }
  }
}
