@import "../../../styles/common/mixins";
@import "../../../styles/common/colors";
@import "../../../styles/common/shadows";
@import "../../../styles/common/buttons";


.switch-profile-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  padding: 4px 8px;
  width: 94%;
  margin-left: 4px;
  cursor: pointer;
  svg {
    path {
      fill: $secondary800;
    }
  }
  &__workspace {

  }
}
.profile {
  display: flex;
  justify-content: space-between;
  color: $primary500;
  font-weight: 400;
  border-bottom: 1px solid $white;
  padding: 8px 0;
  svg {
    width: 16px;
  }
  &:hover {
    color: $secondary800;
    transition: 0.5ms;
    font-weight: 500;
  }
}

.go-to-profile {
  background-color: #004652;
  border: none;
  margin: 8px auto;
}
.other-profiles {
  position: absolute;
  background-color: $white;
  min-width: 350px;
  padding: 16px;
  border-radius: 2px;
  z-index: 1100;
  bottom: 124%;
  left: 8px;
  border: 1px solid $grey300;
  @include z1;
}

.profile-btn {
  @include btnSecondaryColored($primary500, $grey400);
  width: 90%;
  margin: -16px auto auto auto;
}

@include forPhoneOnly {
  .switch-profile-container {
    div {
      flex-direction: row;
    }
  }
}

@include forTabletPortraitUp {
  .switch-profile-container {
    div {
      flex-direction: row;
    }
  }
}
