@import "../../styles/common/mixins";
@import "../../styles/common/colors";

.os-logo {
  display: block;
  width: 150px;
  height: 25px;
  cursor: pointer;
  svg {
    path {
      fill: white;
    }
  }
}

.os-logo-phone {
  display: none;
}

.go-to-website {
  font-size: 12px;
  color: $grey500;
  cursor: pointer;
}

.nav {
  height: fit-content;
  padding: 12px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  border-bottom: 1px solid #DFE3E8;
  background-color: $white;
  z-index: 20;
  width: 96%;
}

.nav-items {
  display: flex;
  gap: 20px;
  position: relative;
  a {
    text-decoration: none;
  }
}

.notifications-container {
  transition: 0.5s;
  cursor: pointer;
  color: white;
  font-size: 14px;
  &:hover {
    transition: 0.5s;
    color: $warning500;
    span{
      display: block;
    };
    .exit {
      display: none;
      path {
        fill: $warning500;
      }
    }
  }
}

.notifications-count {
  position: absolute;
  right: 70px;
  top: -8px;
  background-color: $error500;
  color: $white;
  border-radius: 50%;
  padding: 0 6px;
  font-size: 12px;
}

.nav-burger {
  display: none;
  width: 0;
}

.desktop-only {
  display: block;
}
.public-nav {
  display: none;
}

@include forPhoneOnly {
  .nav {
    padding: 8px 12px;
    gap: 16px;
  }
  .os-logo {
    width: 100px;
  }
  .desktop-only {
    display: none;
  }

  .nav-items {
    gap: 8px;
  }

  .public-nav {
    width: 100%;
    padding: 24px;
    display: none;
    justify-content: space-between;
    background-color: $primary500;
    position: fixed;
    z-index: 20;
  }
  .notifications-count {
    right: 45px;
  }
}
