@import '../../../styles/common/buttons';
@import '../../../styles/common/colors';

.user-signup-form {
  width: 100%;
}
.user-signup-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  & > * {
    width: 100%;
  }
  &-names {
    display: flex;
    gap: 16px;
    & > * {
      width: 43%;
    }
  }
}

.user-signup-btn-container {
  width: 100%;
}
.user-signup-btn {
  @include btnLargeMainColored($primary500, $primary600);
  width: 100%;
  padding: 16px 22px;
}

.disabled-text-field {
  background-color: $grey200;
  .MuiInputBase-input.Mui-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
