@import "../../../src/styles/common/colors";
@import "../../../src/styles/common/mixins";
@import "../../../src/styles/common/card";

.search-promotion-container {
  justify-content: space-between;
  align-items: center;
  background-color: $grey200;
  padding: 16px;
  border-radius: 2px;
  margin-top: 32px;
  .add-coupon-form {
    width: 100%;
  }
}

.MuiInputLabel-outlined {
  background-color: transparent !important;
  margin: -8px 0;
}
.MuiOutlinedInput-input {
  padding: 13.5px 14px !important;
}

.promotions-explanations-container {
  @include card;
  height: auto;
  border: 1px solid $grey400;
  padding: 16px;
  border-radius: 2px;
}

.promotions-explanations-step {
  width: 100%;
  align-items: center;
  flex-direction: row !important;
  svg {
    width: 100px;
    height: 100px;
  }
  .step {
    max-width: 65%;
  }
}


.promotions-container {
  margin: 16px;
}

.half-loader-element {
  width: 32%;
}

.top-promotions-container {
  margin: 16px;
}


@include forTabletLandscapeUp {
  .promotions-explanations-container {
    padding: 16px 32px;
  }

  .promotions-explanations-step {
    width: 31%;
  }
  .top-promotions-container {
    margin: 32px;
  }

  .search-promotion-container {
    .add-coupon-form {
      width: 33%;
    }
  }
  .promotions-container {
    margin: 24px 16px;
  }
}
