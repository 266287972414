@import "../../../styles/common/colors";

.graph-range-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.graph-range-buttons button {
  padding: 10px;
  background-color: $secondary300;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.graph-range-buttons button.active {
  background-color: $primary500;
  color: white;
}

.graph-range-buttons button:hover {
  background-color: $primary500;
  color: white;
}

.graph-page-container {
  padding: 24px;
}

.graphs_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}


.table-container {
  margin-top: 32px;
  margin-bottom: 64px;
}

.stats-filter-input {
  width: 25%;
}
