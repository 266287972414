@import "../../../styles/common/card";
@import "../../../styles/common/colors";
@import "../../../styles/common/mixins";
@import "../../../styles/common/buttons";
@import "../../../styles/common/shadows";
@import "../../../styles/common/typography";

.product-view-container {
  padding: 0 8px;
  .MuiTabs-root {
    margin-left: -40px !important;
    background-color: $secondary100;
    border-bottom: 1px solid $grey300;
  }

  .MuiTab-root {
    min-height: 30px !important;
    margin-bottom: -4px !important;
    border: 1px solid transparent;
  }

  .Mui-selected {
    color: $primary500 !important;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
    padding: 0 8px !important;
    border: 1px solid $primary500;
    border-bottom: 0;
    svg {
      path {
        fill: $primary500;
      }
    }
  }
}

.public-container {
  padding-top: 80px !important;
}

.product-view-title {
  display: block;
  margin: 16px 0;
  h4 {
    color: $primary500;
  }
}

.product-view-category {
  color: $grey700;
  font-weight: bold;
}

.product-view-top-infos {
  display: block;
  min-height: 300px;
  height: 324px;
  margin-bottom: 32px;
  &__cgp {
    height: 416px;
    margin-bottom: 44px;
  }
  &__invested {
    height: 350px;
  }
  &-left {
    display: flex;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: 324px;
    border-radius: 2px;
    background-size: cover;
    .SmallSlider {
      flex-grow: 1;
    }
  }
  &-right {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 24px;
    background-color: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(4px);
    padding: 16px;
    border-radius: 2px;
    font-size: 14px;
    height: fit-content;
    width: 100%;
    z-index: 10;
    input:focus ~ label, input:valid ~ label {
      background-color: transparent;
    }
    &_public {
      width: 90%;
    }
  }
}

.product-view-contact-us {
  display: block;
  background-color: $secondary400;
  padding: 24px 16px;
  border-radius: 2px;
  margin: 0 0 24px 0;
  &-left {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    margin-bottom: 16px;
  }
  &-img {
    width: 120px;
    height: 70px;
  }
  &-content {
    width: 100%;
    p {
      margin: 0;
      color: $secondary800;
    }
  }
  &-btns {
    width: 100%;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
  }
}

.opportunity-card-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-view-sections-navigation {
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position:sticky;
  top: 0;
  background-color: $white;
  border-bottom: 1px solid $secondary300;
  z-index: 2;
  svg {
    color: $primary500 !important;
  }
}

.circle {
  border-radius: 50%;
  background-color: $grey500;
  width: 4px;
  height: 4px;
}

.product-ariane-nav {
  display: none;
  padding: 16px 8px;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  &-item {
    font-size: 14px;
    color: $grey700;
    text-decoration: none;
    &-disabled {
      color: $primary400;
    }
  }
}

.invest-in-product-card {
  box-shadow: none !important;
  height: auto;
  border: 1px solid $grey300;
  border-radius: 2px;
  padding: 16px ;
  &-btns {
    gap: 10px;
    width: 100%;
    margin: 20px auto;
  }
}

.invest-btn {
  @include btnLargeMainColored($primary500, $primary600);
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

.invest-btn-tab {
  @include btnLargeMainColored($primary500, $primary600);
  display: none;
}

.write-us-btn {
  @include btnSecondaryColored($primary500, $grey400);
  height: 35px;
  transition: 0.5s;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  &-secondary {
    @include btnMainSecondaryColored;
    width: 100%;
    height: 35px;
  }
  svg {
    path {
      fill: $primary500;
    }
  }
  &:hover {
    transition: 0.5s;
    svg {
      path {
        fill: $primary500;
      }
    }
  }
}

.product-tabs-container {
  margin-bottom: 40px;
}

.talk-to-someone-btn {
  @include btnLargeSecondaryColored($primary500, $grey400);
  width: 100%;
  &-primary {
    @include btnLargeMainColored($primary500, $primary600);
  }
}
.product-view-short-description {
  margin-left: 16px;
  margin-right: 16px;
  border-bottom: 1px dashed $grey400;
  padding-bottom: 20px;
  margin-bottom: 20px;
  p {
    font-size: 16px;
  }
}

.invest-in-product {
  margin: 24px 0;
}

.deposit-form-container {
  margin-top: 8px;
  width: 100%;
  .MuiOutlinedInput-input {
    padding: 2px 4px 7.5px 5px !important;
  }
}
.choose-client-container {
  margin-bottom: 8px;
  p {
    color: $grey800;
    font-weight: 400;
  }
}

.MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
  font-weight: 600 !important;
  color: $primary500 !important;
  opacity: 0.6 !important;
}
.MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: $primary500 !important;
  font-weight: 700;
  opacity: 1 !important;
}

.MuiButtonBase-root-MuiTab-root.Mui-disabled {
  opacity: 0.3 !important;
  color: $primary500 !important;
  cursor: no-drop !important;
  pointer-events: auto;
}

.opportunity-view-element-container {
  margin: 32px 0;
  div {
    font-family: 'Inter', sans-serif;
    line-height: 1.5;
    color: $primary500 !important;
    font-weight: 300 !important;
  }

  blockquote{
    font-size: 12px;
    width:100%;
    margin: 0;
    font-family: 'Inter', sans-serif;
    color: $grey600;
    font-weight: 600;
    padding: 0 40px;
    line-height:1.6;
    position: relative;
  }

  blockquote::before{
    font-family: 'Inter', sans-serif;
    content: "\201C";
    color:$primary400;
    font-size:5em;
    position: absolute;
    left: -8px;
    top: -33px;
  }

  blockquote::after{
    font-family: 'Inter', sans-serif;
    content: "\201C";
    color:$primary400;
    font-size: 5em;
    position: absolute;
    rotate: 180deg;
    right: 0px;
    top: -54px;
  }
  td {
    div {
      line-height: 1.5;
      color: $primary500 !important;
      font-weight: 500 !important;
    }
  }
  figure {
    max-width: 100%;
    margin: 0 !important;
    img {
      width: 100%;
      height: auto !important;
    }
  }
}

.expert-analysis-container {
  display: block
}

.expert-card {
  width: 100%;
  padding: 16px;
  color: $info400 !important;
  font-weight: 400;
}

.synthesis-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .synthesis-img {
    width: 320px;
    position: relative;
    img {
      width: 100%;
      min-height: 500px;
      max-height: 500px;
      @include cardShadow;
    }
  }
  .synthesis-video-play {
    position: absolute;
    top: 45%;
    left: 40%;
    svg {
      color: white;
      font-size: 64px;
      cursor: pointer;
    }
  }
}

.expert-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 24px;
}

.expert-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: $grey200;
  color: $grey500;
  border-radius: 50%;
  object-fit: cover; /* Garantit que l'image conserve son rapport hauteur/largeur tout en remplissant le cercle */
  &--small {
    width: 48px;
    height: 36px;
    font-size: 18px;
    line-height: 18px;
  }
  &--medium {
    width: 50px;
    height: 48px;
    font-size: 24px;
    line-height: 24px;
  }
  &--large {
    width: 80px;
    height: 82px;
    font-size: 40px;
    line-height: 40px;
  }
}

.expert-infos {
  &-names {
    font-size: 16px;
    font-weight: 600;
  }
}

.page-tabs {
  padding: 0 16px;
  width: 100%;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  font-size: 12px !important;
}

.css-1atmgwh {
  text-transform: capitalize !important;
}

.chips-container {
  display: flex;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.public-content-container {
  position: relative;
  margin-bottom: 64px;
}

.public-card-container {
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 32px 0;
}

.public-card {
  height: fit-content;
  min-height: 280px;
  width: 80%;
  @include card;
}

@include forTabletLandscapeUp {
  .product-view-container {
    padding: 0 0 128px 0;
  }
  .product-ariane-nav {
    display: flex;
    padding: 16px 32px;
  }

  .product-view-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 32px;
  }

  .product-view-top-infos {
    margin: 0 32px;
    display: flex;
    &-left {
      height: auto;
    }
    &-right {
      width: 500px;
      top: 32px;
      right: 24px;
      bottom: 24px;
    }
  }

  .product-view-contact-us {
    display: flex;
    align-items: center;
    margin: 24px 32px 24px 32px;
    &-left {
      margin-bottom: 0;
    }
    &-content {
      width: 80%;
    }
    &-btns {
      width: 50%;
    }
  }
  .product-view-sections-navigation {
    margin: 20px 32px;
    overflow: visible;
  }
  .invest-btn-tab {
    display: block;
  }
  .product-tabs-container {
    margin: 40px 32px;
  }
  .product-view-short-description {
    margin-left: 32px;
    margin-right: 32px;
  }

  .invest-in-product {
    margin: 20px 32px;
  }

  .invest-in-product-card {
    &-btns {
      width: 30%;
      margin: auto;
    }
  }
  .expert-analysis-container {
    display: flex;
    gap: 20px;
  }
  .expert-card {
    width: 100%;
  }
  .opportunity-view-element-container {
    figure {
      max-width: 100%;
      margin: 0 !important;
      height: auto !important;
      img {
        width: 100%;
        height: auto;
      }
    }
  }


  .synthesis-container {
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    .synthesis {
      width: 70%;
    }
  }

  .page-tabs {
    padding: 0 32px;
    width: 50%;
  }
  .public-card {
    min-height: 240px;
    width: 40%;
  }
}

.partner-img {
  width: 120px;
}

@include forDesktopUp {
  .css-h0q0iv-MuiButtonBase-root-MuiTab-root {
    font-size: 14px !important;
  }

  .invest-btn-tab {
    font-size: 14px;
    font-weight: bold;
  }
  blockquote {
    font-size: 14px;
  }

  .synthesis-container {
    .synthesis-img {
      img {
        min-height: 570px;
        max-height: 570px;
      }
    }
  }
  .public-card {
    min-height: 200px;
    width: 60%;
  }
}
