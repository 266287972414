@import "../../styles/common/colors";
@import "../../styles/common/mixins";
@import "../../styles/common/buttons";
@import "../../styles/common/card";

.choose-payment-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__button {
    @include btnLargeMainColored($primary500, $primary600);
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.amount-recap-container {
  align-items: center;
  gap: 8px;
}

.amount {
  line-height: 18px;
  text-transform: uppercase;
}

.tabs-column {
  flex-direction: column !important;
}

.paiement-btn-container {
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.paiement-choices {
  margin-bottom: 24px;
  justify-content: space-between;
  & > * {
    width: 100% !important;
  }
}

@include forTabletLandscapeUp {
  .paiement-choices {
    flex-wrap: wrap;
    & > * {
      width: 48.5%;
    }
  }
}


