@import "../../../../styles/common/colors";
@import "../../../../styles/common/mixins";

.client-flex-container {
  height: fit-content;
  margin-bottom: 32px;
  align-items: stretch;
}

.client-infos {
  align-items: flex-start;
}

.clients-chart-container {
  width: 100%;
  height: auto;
  .product-document {
    width: 100% !important;
    &:hover {
      cursor: pointer;
    }
    .product-document-name {
      max-width: 100%;
    }
  }
}

.client-uploads {
  width: 100%;
  flex-wrap: wrap;
}

.client-documents {
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
}

.clients-top-container {
  margin: 0 0 16px 0;
}

.clients-top-container-btns {
  align-items: flex-start;
  gap: 12px;
  margin-top: 24px;
}

.profile-badges-container {
  align-items: flex-start;
  margin-bottom: 24px;
}

.investments-form {
  display: flex;
  gap: 24px;
  margin: 24px 0;
  width: 30%;
  font-size: 13px;
  & > * {
    width: 100%;
  }
}
.cgp-investment-table {
  width: 100%;
}

@include forTabletPortraitUp {
  .client-infos {
    align-items: flex-start;
    gap: 40px;
    justify-content: space-between;
    max-width: 85%;
    & > * {
      min-height: 270px;
    }
  }

  .client-flex-container {
    gap: 24px;
  }

  .clients-chart-container {
    height: auto;
    max-height: 300px;
    .chart-card {
      box-shadow: none !important;
      width: 100% !important;
    }
  }
  .clients-top-container {
    align-items: flex-end;
    justify-content: space-between;
    h5 {
      margin-bottom: 0;
    }
  }
  .clients-top-container-btns {
    align-items: center;
  }
  .client-documents {
    gap: 16px;
    flex-wrap: wrap;
    & > * {
      width: 32%;
    }
  }
  .profile-badges-container {
    margin-bottom: 0;
  }
}
