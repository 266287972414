@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/card";
@import "../../styles/common/buttons";

.white-container {
  @include card;
  margin: 20px auto;
  height: 90%;
}

.kyc-container {
  margin: 10px auto 50px auto;
}

.stepper-kyc {
  width: 100%;
  margin: auto;
}

.white-cards {
  transition: 0.4s;
  background-color: $white;
  margin: 20px 0;
  padding: 32px 16px;
  border-radius: 10px;
  border: 1px solid $primary500;
  cursor: pointer;
  &:hover {
    transition: 0.6s;
    background-color: $primaryLightGradient;
    & > * {
      color: $primary500;
    }
  }
}

.email-link {
  color: $primary500;
  font-weight: 600;
}

.nb-upload-error {
  color: $grey600;
  font-size: 12px;
}

.rollback-btn {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.choices-container {
  display: flex;
  flex-direction: column;
  & > * {
    width: 100%;
  }
}

@include forTabletLandscapeUp {
  .white-container {
    width: 90%;
  }
  .stepper-kyc {
    width: 90%;
  }
  .choices-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > * {
      width: 48%;
    }
  }
}
