@import "../../../styles/common/colors";
@import "../../../styles/common/mixins";
@import "../../../styles/common/buttons";


.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boost-text {
  display: flex;
  padding-left: 16px;
}

.button-style{
  display: flex;
  width: 100%;
  margin-top: 20px;
}



.botw-container{
  display: flex;
  flex-wrap: wrap;
}

.date-btn{
  margin-top: 20px;
  @include btnMainColored($primary500, $primary600);
}

@include forTabletLandscapeUp {
  .button-style{
    width: 100%;
  }
}

@include forPhoneOnly{
  .boosts-button{
    margin-bottom: 20px;
  }
}

@include forTabletPortraitUp{
  .boosts-button{
    margin-bottom: 20px;
  }
}
