@import "common/mixins";
@import "common/colors";
@import "common/shadows";
@import "common/card";
@import "common/toast";

html {
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
  color: $grey800;
  & > * {
    -webkit-transition:0.2s;
    -moz-transition:0.2s;
    -o-transition:0.2s;
    transition:0.2s;
  }
  scrollbar-width: none;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: white !important;
  margin: 0;
  color: $grey800 !important;
  body::-webkit-scrollbar{
    display: none;
    -ms-overflow-style: none;
  }
}

.os-flex {
  display: flex;
  flex-direction: column;
  &__element {
    &-one-third {
      width: 100%;
    }
    &-half {
      width: 100%;
    }
  }
}

.os-flex__column {
  display: flex;
  flex-direction: column;
}

.os-grid {
  display: grid;
  align-items: center;
}

.one-grid {
  grid-template-columns: repeat(1, 1fr);
}
.two-grid {
  grid-template-columns: repeat(2, 1fr);
}
.two-one-grid {
  grid-template-columns: 63% 35%;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px
}

.gap-40 {
  gap: 40px
}

.intercom-lightweight-app-launcher {
  bottom: 80px !important;
}

.MuiTabs-root {
  margin-left: -40px !important;
  background-color: $grey100;
  border-bottom: 1px solid $grey300;
}
.Mui-selected {
  color: $primary500 !important;
  font-weight: 600;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
.Mui-active {
  color: $primary400 !important;
}
.Mui-completed {
  color: $primary400 !important;
}

.MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: $primary300 !important;
}
.MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: $primary400 !important;
}

.MuiInputBase-root {
  color: $grey700 !important;
  border-radius: 4px;
  min-height: 46px !important;
}

.MuiOutlinedInput-root {
  border-radius: 2px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary500 !important;
  border-width: thin !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary500 !important;
}

.MuiInputLabel-outlined {
  background-color: transparent !important;
  padding: 1px 0 !important;
}

.input {
  &:focus {
    border-color: $primary500 !important;
  }
  &:hover {
    border-color: $primary500 !important;
  }
}

.MuiStepper-root {
  overflow-x: scroll;
}

.MuiCard-root {
  @include cardShadow;
  box-shadow: none !important;
}
.MuiTableHead-root {
  background-color: $secondary200 !important;
}
.MuiTableCell-root {
  color: $grey700 !important;
  height: 70px !important;
  border-radius: 2px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.css-187mznn-MuiSlider-root {
  color: $primary600 !important;
}
span.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium {
  color: $primary400 !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: $primary400 !important;
}

.tabs{
  border: 1px solid $grey300;
  border-radius: 2px;
  margin-bottom: 24px;
  .tab{
    width: 48.5% !important;
    margin: 4px;
    text-transform: capitalize;
    font-weight: 600;
  }
  .Mui-selected {
    color: $white !important;
    background-color: $primary500;
    border-radius: 2px;
  }
  .MuiTabs-flexContainer {
    justify-content: center;
  }
  .MuiTabs-root {
    margin-left: 0 !important;
  }
  .MuiTabs-indicator {
    background-color: transparent !important;
  }
  button{
    width: 50%;
  }
}

.chart-card {
  padding: 8px;
  position: relative;
  @include card;
  @include z16;
  height: auto;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  .empty-content-btn {
    margin: auto auto 0 auto !important;
  }
}

.chart-card-title {
  margin-left: 16px;
  margin-bottom: 50px;
}

.apexcharts-text.apexcharts-datalabel-value {
  color: $primary500 !important;
}

.apexcharts-text.apexcharts-datalabel-value {
  font-size: 2rem !important;
}

.apexcharts-legend-series {
  margin: 10px 12px !important;
}

.apexcharts-legend.apexcharts-align-bottom.apx-legend-position-top {
  height: 80px;
  top: calc(280px)!important;
}

.tooltip-btn {
  color: $grey500;
  cursor: pointer;
  display: flex;
}

.info-text {
  color: $info500;
}

.os-scroll-shadows {
  overflow: auto;

  background-image:
          /* Shadows */ linear-gradient(to right, white, white),
  linear-gradient(to right, white, white),
    /* Shadow covers */
  linear-gradient(
                  to right,
                  rgba($grey800, 0.25),
                  rgba($grey100, 0)
  ),
  linear-gradient(
                  to left,
                  rgba($grey800, 0.25),
                  rgba($grey100, 0)
  );

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.table-card {
  position: relative;
  background-color: white;
  width: 65%;
  border-radius: 2px;
  cursor: pointer;
  min-height: 430px;
  height: auto;
  &--empty {
    width: 100% !important;
    & > * {
      width: 100% !important;
    }
  }
}

.MuiInputBase-input {
  font-size: 11px !important;
}

.back-to-docs {
  color: $grey600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  margin-bottom: 24px;
  svg {
    width: 20px;
    height: 20px
  }
}

.light-border {
  border-bottom: 1px solid $grey400;
}

.error {
  transition: 0.5s;
  font-size: 12px;
  margin-top: 2px;
  color: #f44336 !important;
}
.success {
  transition: 0.5s;
  font-size: 12px;
  margin-top: 2px;
  color: $success600 !important;
}
.warning {
  transition: 0.5s;
  font-size: 12px;
  margin-top: 2px;
  color: $warning500 !important;
}

.round-icon {
  justify-content: center;
  align-items: center;
  padding: 6px;
  background-color: $primary100;
  border-radius: 50%;
  width: fit-content;
  svg {
    color: $primary500;
    width: 18px;
    height: 18px;
  }
}

.stats-card {
  padding: 8px 16px;
}

.table-link {
  color: $primary500;
  font-weight: 500;
  text-decoration: none;
}

#hubspot-messages-iframe-container iframe {
  bottom: 12px !important;
}

@include forTabletPortraitUp {
  .chart-card {
    padding: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

@include forTabletLandscapeUp {
  .apexcharts-legend.apexcharts-align-bottom.apx-legend-position-top {
    height:110px;
  }
  .intercom-lightweight-app-launcher {
    bottom: 20px !important;
  }
  .chart-card {
    width: 30%;
  }
}

@include forTabletLandscapeUp {
  .os-flex {
    display: flex;
    flex-direction: row;
    &__element {
      &-one-third {
        width: 33%;
      }
      &-half {
        width: 49%;
      }
    }
  }
}

@include forDesktopUp {
  .apexcharts-legend{
    overflow: unset !important;
    height: 100px;
  }
  .chart-card {
    width: 32%;
  }
  .MuiInputBase-input {
    font-size: 14px !important;
  }
  .back-to-docs {
    font-size: 14px;
    svg {
      width: 24px;
      height: 24px
    }
  }
}

@include forPhoneOnly{
  .apexcharts-legend{
    overflow: unset !important;
    height: 105px !important;
  }
  .back-to-docs{
    margin-top: 16px;
  }
}
