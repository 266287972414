@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/buttons";

.phone-input-container {
  width: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.phone-form {
  display: flex;
  flex-direction: column;
  & > * {
    margin: 5px 0;
  }
}

.react-tel-input .form-control {
  font-size: 16px;
  background: transparent;
  border: 1px solid #DCE0E4;
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 18.5px 14px 18.5px 58px;
  transition: box-shadow ease .25s,border-color ease .25s;
  &:focus {
    border: 1px solid #DCE0E4;
    box-shadow: 0 0 0 1px #DCE0E4;
  }
}


.phone-submit {
  margin-top: 16px;
  @include btnLargeMainColored($primary500, $primary600);
  padding: 16px;
  .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
  }
  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

@include forPhoneOnly {
  .phone-submit {
    width: 100%;
  }
}
