@import "../../styles/common/colors";
@import "../../styles/common/mixins";
@import "../../styles/common/buttons";
@import "../../styles/common/card";

.account-container {
  gap: 24px;
  padding: 0 16px ;
  margin-top: 24px;
  p {
    font-size: 13px;
  }
  & > * {
    width: 100%;
  }
}

.profile-container {
  width: 100%;
  height: auto;
}

.profile-container-content {
  @include card;
  border-radius: 2px;
  box-shadow: none !important;
  border: 1px solid $grey300;
  height: auto;
  width: 100%;
}

.user-infos-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__button {
    @include btnSecondaryColored($primary500, $grey400);
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover {
      svg {
        path {
          fill: $grey400;
        }
      }
    }
  }
  &__logoutButton{
    @include btnSecondaryColored($error500, $grey400);
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
  }
}

.legal-fields {
  display: flex;
  flex-direction: column;
}

.user-infos-names {
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    margin: 0;
    font-size: 13px;
  }
}

.profile-link {
  color: $primary500;
  text-decoration: none;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 6px;
  font-size: 13px;
  &__disabled {
    color: $disabled;
  }
  &:hover {
    background-color: $primaryLightGradient;
    font-weight: 700;
    transition: 0.2s;
  }
}

.field-title {
  color: $primary500;
  font-weight: bold;
  font-size: 13px;
}

.section-title {
  color: $primary500;
  justify-content: space-between;
  align-items: center;
}

.add-company-block {
  background-color: $grey200;
  color: $primary500;
  width: 100%;
  height: 200px;
  border-radius: 2px;
  border: 2px dashed $grey600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@include forTabletLandscapeUp {
  .account-container {
    flex-direction: row;
    padding: 0 32px;
  }
  .profile-container {
    width: 50%;
  }
}
