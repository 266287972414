@import "../../../styles/common/mixins";
@import "../../../styles/common/card";
@import "../../../styles/common/buttons";

.investment-container {
  padding: 0 16px;
  margin-top: 16px;
}

.investment-cards-container {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}
.investment-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include card;
  border-radius: 2px;
  padding: 16px;
  height: auto;
  &-title {
    align-items: flex-start;
  }
}

.investment-top-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.investment-sold {
  color: $grey400;
  &-card {
    display: flex;
    align-items: center;
    gap: 16px;
    color: $white;
    margin: 0;
  }
}
.left-container {
  width: 100%;
}

.investment-chart {
  width: 100%;
}

.slider-livretp {
  display: none;
}

.slider-mobile-livretp {
  display: block;
  height: 300px !important;
}

.investment-btn {
  @include btnMainColored($primary500, $primary600);
  border-radius: 50px;
  width: 100%;
}
.my-investment-btns {
  align-items: flex-start;
  gap: 16px;
  margin: 24px 0;
}

@include forTabletPortraitUp {
  .slider-livretp {
    display: block;
    width: 33%;
  }
  .slider-mobile-livretp {
    display: none;
  }
  .investment-btn {
    width: 20%;
  }
  .my-investment-btns {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    div {
      flex-direction: row !important;
    }
  }
}

@include forTabletLandscapeUp {
  .investment-card {
    &-title {
      justify-content: space-between;
    }
  }
  .investment-container {
    padding: 0 32px;
  }
  .investment-cards-container {
    flex-direction: row;
    gap: 32px;
    & > * {
      width: 31%;
    }
  }

  .investment-top-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .investment-chart {
    width: 35%;
  }
  .investment-btn {
    max-width: 15%;
  }
  .my-investment-btns {
    align-items: center;
    justify-content: space-between;
  }
}
