@import '../../styles/common/colors';
@import '../../styles/common/buttons';
@import '../../styles/common/mixins';

.react-joyride__tooltip {
  padding-right: 32px !important;
  button {
    @include btnLargeMainColored($primary500, $primary600);
    padding: 11px 11px !important;
    strong {
      width: 420px !important;
    }
  }
  .tour-close {
    position: absolute;
    right: 32px;
    top: 16px;
  }
  div {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    &:nth-child(2) {
      div {
        flex: 0 !important;
      }
      button {
        width: 100% !important;
      }
    }
  }
}

@include forTabletLandscapeUp {
  .react-joyride__tooltip {
    div {
      flex-direction: row !important;
      justify-content: space-between !important;
      &:nth-child(2) {
        button {
          width: 50% !important;
        }
      }
    }
  }

}
