@import "../../../../../styles/common/buttons";
@import "../../../../../styles/common/colors";

.document-frame {
  width: 100%;
  min-height: 200px;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  &-submitting {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sign-document-btn-container {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.sign-document-btn {
  @include btnLargeMainColored($primary500, $primary600);
  span {
    width: 20px !important;
    height: 20px !important;
  }
  &-secondary {
    @include btnLargeSecondaryColored($primary500, $grey400);
  }
}

#scroll-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.pdf-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  height: 500px;
  p {
    color: $primary500;
    font-weight: 600;
  }
}


