@import "../../../styles/common/colors";
.deposit-amount-width{
  width:100% !important;
}
.repartition-indicator-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
}
.repartition-indicator {
  transition: 0.5s;
  font-size: 13px;
  margin-top: 5px;
  &__error {
    color: $error500;
    font-weight: 600;
  }
  &__success {
    color: $success500;
    font-weight: 600;
  }
}
