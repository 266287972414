@import "../../../../../styles/common/colors";

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $lightGrey;
}
.field-name {
  color: $grey500;
}

.field-content {
  color: $primary500;
  font-weight: 600 !important;
}

.field-copy {
  background-color: $grey400;
  color: $primary500;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  opacity: 0.75;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
  gap: 8px;
}

.field-mail {
  color: $primary500;
  font-weight: 600;
  text-decoration: none;
}
