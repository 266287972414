@import './colors';

.Toastify__toast--success {
  background-color: $primaryLightGradient;
  color: $primary800;
  border-left: none;
}

.Toastify__toast--info {
  background-color: $info100;
  color: $info500;
  border-left: none;
}

.Toastify__toast--warning {
  background-color: $warning100;
  color: $warning500;
  border-left: none;
}

.Toastify__toast--error {
  background-color: $error100;
  color: $error500;
  border-left: none;
}

.Toastify__toast-body {
  font-family: Arial, sans-serif;
  padding: 8px 16px;
}

.Toastify__close-button {
  color: inherit;
}
