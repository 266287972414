@import '../../../styles/common/colors';
@import '../../../styles/common/mixins';
.performance-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 16px 0 16px;
  p {
    color: $white !important;
  }
  ul {
    color: $white;
  }
  &__img {
    width: 45%;
    height: 200px;
  }
}

.announcement_container {
  align-items: center;
  justify-content: space-around;
}

.announcement-left {
  max-width: 100%;
}

@include forTabletLandscapeUp {
  .performance-container {
    padding-top: 80px;
    &__img {
      width: 40%;
      height: 400px;
    }
  }

  .announcement-left {
    max-width: 45%;
  }
}
