@import "../../../styles/common/mixins";


.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.success-animation {
  width: 30%;
  margin: auto;
  height: 200px;
}

.success-content {
  text-align: center;
}

.success-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}

@include forTabletPortraitUp() {
  .success-btn {
    flex-direction: row;
    gap: 10px;
    width: 50%;
  }
}
