@use '../../styles/common/colors';
@use '../../styles/common/mixins';

.os-chips-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 8px;
}

.os-chips {
  background-color: transparent;
  color: colors.$primary500;
  border: 1px solid colors.$primary500;
  padding: 1.5px 4px;
  border-radius: 2px;
  width: fit-content;
  &--colored {
    background-color: colors.$success500;
    border: 1px solid colors.$success500;
    color: colors.$white;
  }
  &--transparent {
    background-color: rgba(colors.$webflowGreen, 0.48);
    border: 1px solid rgba(colors.$grey500, 0.32);
    color: colors.$white;
  }
  &--clickable {
    cursor: pointer;
    padding: 4px 8px;
    background-color: transparent;
    border: 1px solid colors.$grey700;
  }
  &--selected {
    background-color: colors.$primary500;
    color: colors.$secondary100 !important;
    border: 1px solid colors.$primary500;
  }
}

@include mixins.forDesktopUp {
  .os-chips {
    padding: 3px 8px;
    &--clickable {
      padding: 8px;
    }
  }
}
