@import '../../../../styles/common/mixins';
@import '../../../../styles/common/card';

.category-investments-container {
  padding: 0 16px;
}

.category-investments-cards {
  display: flex;
  flex-direction: column;
}

.category-investments-infos {
  @include card;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.category-investment-slider {
  width: 100%;
  min-height: 280px;
  max-height: 280px;
}

.category-investment-products {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.investment-details-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__space {
    margin: 16px 0 32px 0;
    border-bottom: 1px solid $grey400;
  }
}

@include forTabletLandscapeUp {
  .category-investments-cards {
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
  }
  .category-investments-infos {
    @include card;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  .category-investment-slider {
    width: 40%;
  }
  .category-investment-products {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    & > * {
      width: 48.5%;
    }
  }
}

@include forDesktopUp {
  .category-investments-container {
    padding: 0 32px;
  }
  .category-investments-cards {
    gap: 32px;
  }
  .category-investment-products {
    & > * {
      width: 31.5%;
    }
  }
}
