@import "../../../styles/common/colors";
@import "../../../styles/common/mixins";
@import "../../../styles/common/buttons";
@import "../../../styles/common/card";

.animation {
  width: 200px;
  height: 200px;
  margin: auto;
}

.deposit-page-container {
  padding: 16px 8px;
}

.deposit-container {
  @include card;
  width: 100%;
  height: 100%;
  .MuiTabs-indicator {
    background-color: transparent !important;
  }
  .MuiTabs-root {
    margin-left: 0 !important;
  }
}

.deposit-product-recap {
  @include card;
  width: 100%;
  height: auto;
}

.deposit-choices {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grey400;
}

.choice {
  background-color: $lightGrey;
  padding: 10px 15px;
  border-radius: 10px;
}

.deposit-btn {
  margin-top: 20px;
  @include btnLargeMainColored($primary500, $primary600);
  font-size: 14px;
}

@include forTabletPortraitUp {
  .deposit-container  {
  }
}

@include forTabletLandscapeUp {
  .deposit-page-container {
    padding: 32px;
    display: flex;
    gap: 24px;
  }
  .deposit-container {
    width: 60%;
  }
  .deposit-product-recap {
    width: 38%;
    min-height: 350px;
    max-height: 450px;
  }
}
