@import "../../../styles/common/colors";
@import "../../../styles/common/shadows";

.section-details {
  background-color: $primaryLightGradient;
  width: 100%;
  margin: 20px auto;
  padding: 5px 15px;
  border-radius: 10px;
}

.section-details__title {
  color: $primary500 !important;
  font-weight: 600 !important;
}

.section-details__number {
  color: $primary500;
}

.section-details__content {
  color: $grey500 !important;
  font-weight: 500 !important;
}
