@import "./colors";
@import "./shadows";

@mixin card {
  padding: 16px;
  min-height: 160px;
  height: auto;
  background: $white;
  border-radius: 2px;
  color: $grey800 !important;
  @include cardShadow;
}

.os-card-border {
  border-radius: 16px;
  padding: 4px 12px;
  figure.attachment.attachment--preview {
    width: 160px !important;
  }
  a {
    text-decoration: none;
    color: $primary400;
    border-bottom: 1px solid $primary400;
    font-weight: bold;
    margin-bottom: 16px;
  }
}
