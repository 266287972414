@import '../../styles/common/colors';
@import '../../styles/common/mixins';

.contact-container {
  background-color: $secondary300;
  border: 1px solid $secondary400;
  border-radius: 2px;
  padding: 16px;
  min-height: 230px;
  margin-bottom: 64px;
}

.contact-left {
  max-width: 100%;
  p {
    color: $grey500;
  }
}
.contact-right {
  border-left: none;
  border-top: 1px solid $grey500;
  width: 100%;
  padding-left: 0;
  padding-top: 16px;
  margin-top: 32px;
}

.contact-user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
  }
}
.name {
  text-decoration: none;
  color: $grey600 !important;
  max-width: 100%;
}

@include forTabletPortraitUp {
  .name {
    max-width: 50%;
  }
}

@include forTabletLandscapeUp {
  .contact-container {
    margin-bottom: 0;
    padding: 16px 32px;
  }

  .contact-left {
    width: 45%;
  }

  .contact-right {
    width: 40%;
    border-left: 1px solid $grey600;
    border-top: none;
    padding-left: 64px;
    padding-top: 0;
    margin: auto 0 auto 0;
    height: 10em;
    h5 {
      margin-top: 8px;
    }
  }

  .name {
    max-width: 90%;
  }
}

@include forDesktopUp {
  .name {
    max-width: 70%;
  }
}
