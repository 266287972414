@import '../../../../styles/common/colors';

.information-recap-content {
  width: 100%;
  color: $grey700;
  &-img {
    width: 100%;
    border-radius: 2px;
    max-height: 145px;
    margin-bottom: 16px;
    object-fit: cover;
    //filter: grayscale(100%) brightness(70%) contrast(100%);
  }
}

.promo-info {
  border: 1px solid $success500;
  color: $success600;
  font-weight: 600;
  border-radius: 5px;
  padding: 2px 16px;
  margin-top: 16px;
  transition: 0.25s all ease-in-out;
}
