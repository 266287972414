@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/buttons";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color : rgba(0,0,0,0.85);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 100%;
}

.modal {
  width: 100%;
  z-index: 100;
  position: relative;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modal-white {
  background: $white;
}

.modal-header {
  background-color: $primary600;
  padding-left: 24px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .os-h5 {
    color: $grey100;
  }
}

.modal-close {
  font-size: 2rem;
  font-weight: 700;
  color: $secondary200;
  cursor: pointer;
  border: none;
  background: transparent;
  margin-right: 16px;
  &-white {
    color: $white;
  }
}

.modal-body {
  width: 100%;
  margin-bottom: 20px;
  background-color: $white;
  padding: 12px 24px;
}

.modal-close-button {
  @include btnLargeSecondaryColored($primary500, $grey400)
}

@include forTabletLandscapeUp() {
  .modal-wrapper {
    width: calc(100% + 50px);
    margin-top: 64px;
    height: auto;
    max-height: 85%;
  }
  .modal {
    min-width: 50%;
    max-width: 50%;
    border-radius: 10px;
  }
}
