$navy: #133251;
$blue: #0091FA;
$blueDarker: #004E87;
$white: #FFFFFF;
$black: #000000;
$blueInfo: #00B8D9;

$webflowGreen: #264c51;

$primary100: #D7F5F2;
$primary200: #B2ECEA;
$primary300: #7EC4C7;
$primary400: #4D878F;
$primary500: #1B3D45;
$primary600: #13303B;
$primary700: #0D2531;
$primary800: #081A28;
$primary900: #051321;

$secondary100: #FBFDFD;
$secondary200: #F8FBFC;
$secondary300: #F2F5F8;
$secondary400: #EAEDF1;
$secondary500: #DFE3E8;
$secondary600: #A3B1C7;
$secondary700: #7083A7;
$secondary800: #475A86;
$secondary900: #2A3C6F;

$grey100: #F9FAFB;
$grey200: #F4F6F8;
$grey300: #DFE3E8;
$grey400: #C4CDD5;
$grey500: #919EAB;
$grey600: #637381;
$grey700: #454F5B;
$grey800: #212B36;
$grey900: #161C24;

$success100: #D8F9CF;
$success200: #ABF3A2;
$success300: #6FDC6D;
$success400: #45BA4E;
$success500: #178C2B;
$success600: #10782C;
$success700: #0B642B;
$success800: #075128;
$success900: #044326;

$info100: #C7F9F5;
$info200: #92F3F3;
$info300: #58D3DD;
$info400: #2EA6BB;
$info500: #006F8E;
$info600: #00567A;
$info700: #004066;
$info800: #002E52;
$info900: #002144;

$warning100: #FCEDCF;
$warning200: #F9D8A1;
$warning300: #ECB770;
$warning400: #DB954A;
$warning500: #c36818;
$warning600: #A74F11;
$warning700: #8C3A0C;
$warning800: #712807;
$warning900: #5D1B04;

$error100: #F9D7CD;
$error200: #F4A99E;
$error300: #E06E69;
$error400: #C14148;
$error500: #991226;
$error600: #830D29;
$error700: #6E092A;
$error800: #580529;
$error900: #490327;

$warningMainRgba: #FFAB00;
$warningDarkRgba: rgba(255, 171, 0, 0.2) !important;
$successDarkRgba: rgba(0, 215, 163, 0.2) !important;

$bluePastel: #F6F8FF;
$borderBlue: #E2EAFE;

$lightGrey: #637381;
$purple: #7443FF;

$disabled: $grey500;

$primaryLightGradient: #EEF1F1;
$warningLightGradient: linear-gradient(90deg, rgba(255,171,0,0.2) 0%, rgba(255,171,0,0.2) 100%);
$blueLightGradient: linear-gradient(90deg, rgba(0,145,250,0.2) 0%, rgba(0,145,250,0.2) 100%);
$greenLightGradient: linear-gradient(90deg, rgba(0,215,163,0.2) 0%, rgba(0,215,163,0.2) 100%);
$errorLightGradient: linear-gradient(90deg, rgba(255,86,48,0.2) 0%, rgba(255,86,48,0.2) 100%);
$purpleLightGradient: linear-gradient(90deg, rgba(116,67,255,0.2) 0%, rgba(116,67,255,0.2) 100%);
$horizMain: #7442ec;
$horizCta: #46bcaa;
