@import '../../../styles/common/mixins';

.clients-container {
  padding: 32px 16px;
  background-color: $grey100;
}

@include forTabletPortraitUp {
  .clients-container {
    padding: 32px 48px;
    margin: auto;
  }
}
