@import '../../../../styles/common/colors';
@import "../../../../styles/common/mixins";

.os-profile-avatar {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  &__border {
    border-bottom: 1px solid $grey400;
    padding-bottom: 16px;
  }
  &__menu {
    padding: 8px 0;
  }
}

.os-current-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600 !important;
  min-width: 36px;
  min-height: 36px;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  &__larger {
    min-width: 42px;
    min-height: 42px;
  }
  &__yellow {
    background-color: $warning100;
    color: $warning500;
  }
  &__blue {
    background-color: $info100;
    color: $info600;
  }
  &__green {
    background-color: $success100;
    color: $success700;
  }
  &__red {
    background-color: $error100;
    color: $error600;
  }
  &__secondary {
    background-color: $secondary300;
    color: $secondary800;
  }
  &__link {
    text-decoration: none;
    color: $primary500 !important;
    font-size: 14px;
  }
}

.os-current-profile-infos {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.os-other-profiles {
  display: flex;
  color: #FE9C0A;
  font-weight: 600 !important;
  align-items: center;
}
