@import "../../styles/common/colors";
@import "../../styles/common/card";
@import "../../styles/common/mixins";
@import "../../styles/common/buttons";

.referral-container {
  padding: 0 16px;
}
.referral-title {
  justify-content: space-between;
}

.referral-code-copy {
  background-color: $grey200;
  padding: 0 16px;
  border-radius: 2px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
  p {
    color: $primary500;
    font-weight: bold;
    font-size: 14px;
  }
}

.bottom-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-invitation-container {
  justify-content: space-between;
  align-items: center;
  background-color: $grey200;
  padding: 16px;
  border-radius: 2px;
  margin-top: 32px;
  .add-coupon-form {
    width: 100%;
  }
}


@include forTabletLandscapeUp {
  .referral-container {
    padding: 16px 32px;
  }
  .referrals-container  {
    width: 50%;
    margin: 30px auto 30px auto;
  }

  .btn-container{
    width: 40%;
  }

  .MuiStepper-root {
    overflow: unset !important;
  }

  .search-invitation-container {
    .add-coupon-form {
      width: 33%;
    }
  }
}
