@import "../../../styles/common/card";
@import "../../../styles/common/colors";
@import "../../../styles/common/mixins";
$assets-img-path: "../../../assets/images";


.investment-card-recap {
  @include card;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  &__infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.product-information-text {
  div {
    font-family: 'Inter', sans-serif;
    color: $grey800 !important;
    font-weight: 400!important;
    line-height: 22px;
  }
  ul{
    padding-left: 0;
  }
  li {
    background: url('#{$assets-img-path}/ic_check_outline.svg') no-repeat left center;
    padding: 10px 10px 10px 42px;
    list-style: none;
    margin: 0;
    vertical-align: middle;
    font-size: 12px;
  }
}

@include forDesktopUp{
  .loader-display {
    .desktop-display{
      display: block ;
    }
    .mobile-display {
      display: none ;
    }
    .product-information-text {
      li {
        font-size: 14px;
      }
    }
  }
}

@include forTabletPortraitUp{
  .loader-display {
    .desktop-display{
      display: none ;
    }
    .mobile-display {
      display: block ;
    }

  }
}
@include forTabletLandscapeUp{
  .loader-display {
    .desktop-display{
      display: block ;
    }
    .mobile-display {
      display: none ;
    }

  }
}


@include forPhoneOnly {
  .loader-display{
    .desktop-display{
      display: none;
    }
    .mobile-display{
      display: block ;
    }
  }
}





