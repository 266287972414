@import "../../styles/common/colors";
@import "../../styles/common/mixins";
@import "../../styles/common/buttons";
@import "../../styles/common/card";

.boost-card {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #C4CDD5;
  padding: 10px;
  border-radius: 20px;
  &-opacity {
    opacity: 0.2;
  }
}

.boost-card-content {
  width: 65%;
}

.boost-card-status {
  color: $success500;
  font-weight: bold;
  &__expired {
    color: $grey600;
  }
}

.top-boosts-container {
  margin: 16px;
}
.boosts-list {
  flex-wrap: nowrap;
  flex-direction: row !important;
  overflow: auto;
  white-space: nowrap;
}

.boosts-container {
  margin: 32px;
}

.boost-card-percentage {
  color: $primary500;
  font-weight: bold;
  font-size: 15px;
  margin: 15px 0 0 0;
}

.boost-card-duration {
  font-size: 12px;
  margin: 0;
}
.invite-image-container{
  height: 206px;
  margin-top: 24px;
}

@include forTabletPortraitUp {
  .encompasses-container{
    padding: 16px;
  }
  .boosts-container  {
    width: 70%;
    margin: 10px auto 50px auto;
  }
  .back-to-docs{
    margin-top: 10px;
  }
}

@include forDesktopUp {
  .boosts-container  {
    width: 50%;
  }
}

@include  forPhoneOnly{
  .encompasses-container{
    padding: 16px;
  } .title-container{
  background-position-x: center ;
    height: 160px;
  }
}
