@import '../../styles/common/colors';
@import '../../styles/common/buttons';
@import '../../styles/common/mixins';

.finalise-product-document {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid $grey400;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  overflow: hidden;
  &-name {
    color: $primary500;
    font-weight: 600;
    word-break: break-all;
    margin: 0;
  }
  &-download {
    color: $grey800;
    font-size: 12px;
    margin: 0;
  }
  &-button {
    @include btnLargeMainColored($primary500, $primary600);
  }
  &-button-secondary {
    @include btnSecondaryColored($primary500, $grey400);
  }
  &-icon {
    width: 50px;
    height: 50px;
  }
  &-signed-container {
    min-width: 15%;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &-signed {
    color: $success500;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.pdf-logo-container {
  background-color: $secondary300;
  padding: 8px 8px 4px 8px;
  border-radius: 2px;
  flex-shrink: 0;
  svg {
    color: $primary500;
  }
}

@include forTabletLandscapeUp {
  .finalise-product-document {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
}
