@import './mixins';
@import './colors';
@import '../fonts/fonts';

h1,h2,h3,h4,h5,h6 {
  font-family: 'Brother', sans-serif !important;
  letter-spacing: 0.27px;
}
h2 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  color: $grey700;
}

h3 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: $grey700;
}


h4 {
  display: block;
  font-size: 1.17em;
  margin-block-start:  0.67em;
  margin-block-end:  0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: $grey700;
}


h5 {
  display: block;
  margin-block-start:  0.67em;
  margin-block-end:  0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: $grey700;
}

h6 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: $grey700;
}

.os-h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  margin: 0;
}

.os-h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;

}

.os-h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

}

.os-h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.os-h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

}

.os-h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;

}

.os-subtitle1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.os-subtitle2 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.os-body1 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 24px !important;
  color: $grey600;
  text-decoration: none;
}

.os-body2 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 22px !important;
  color: $grey600;
  text-decoration: none;
}

.os-caption {
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px;
  color: $grey600;
}

.os-overline {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

@include forTabletLandscapeUp {
  .os-h1 {
    font-size: 64px;
    line-height: 80px;
  }

  .os-h2 {
    font-size: 48px;
    line-height: 64px;
  }

  .os-h3 {
    font-size: 32px;
    line-height: 48px;
  }

  .os-h4 {
    font-size: 24px;
    line-height: 36px;
  }

  .os-h5 {
    font-size: 20px;
    line-height: 30px;
  }

  .os-h6 {
    font-size: 18px;
    line-height: 28px;
  }

  .os-body1 {
    font-size: 16px;
  }

  .os-body2 {
    font-size: 14px;
  }
}
