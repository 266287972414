@import "../../../../styles/common/colors";
@import "../../../../styles/common/mixins";
@import "../../../../styles/common/card";

.banks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  & > * {
    width: 45%;
  }
}

.choose-container {
  width: 90%;
  margin: 30px auto auto auto;
}

.bank-card {
  @include card;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  svg {
    margin: auto;
    width: 2em;
    height: 2em;
  }
  &__img {
    max-height: 60px;
    margin-bottom: 10px;
  }
  &__name {
    font-size: 14px;
    min-height: 30px;
    text-align: center;
    max-width: 80%;
    margin: auto;
  }
}


@include forTabletPortraitUp {
  .banks {
    & > * {
      width: 31%;
    }
  }
}

@include forTabletLandscapeUp {
  .banks {
    & > * {
      width: 18%;
    }
    &__img {
      max-height: 80px;
    }
  }
}
