@import "../../../styles/common/colors";

.mobile-nav-container {
  z-index: 100;
  background-color: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgb(145 158 171 / 20%) 0px 3px 3px -2px, rgb(145 158 171 / 14%) 0px 3px 4px 0px, rgb(145 158 171 / 12%) 0px 1px 8px 0px
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  color: $grey600;
  width: 30%;
  &__active {
    background: $secondary300;
    color: $primary500;
    border-radius: 5px;
    padding: 5px;
  }
}
