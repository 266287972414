@import "../../styles/common/colors";
@import "../../styles/common/mixins";

.more-container {
  position: relative;
  margin: 84px 24px 0 24px;
  height: 80vh;
}

.more-title {
  color: $primary500;
}

.more-links {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 5px;
  &__link {
    text-decoration: none !important;
    color: $grey700 !important;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97%;
    gap: 20px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.profile-switch-container {
  position: absolute;
  bottom: 64px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  width: 90%;
  & > * {
    width: 100%;
  }
}
