@import "../../../styles/common/colors";
@import "../../../styles/common/mixins";
@import "../../../styles/common/shadows";

.mySwiper {
    height: 100%;
    cursor: grab;
    margin-bottom: 32px;
    &:active {
        cursor: grabbing;
    }
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 0;
        left: 0;
        width: 100%;
    }
    span.swiper-pagination-bullet {
        background-color: $primary500 !important;
        opacity: 0.4 !important;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: $primary500 !important;
        opacity: 1 !important;
    }
    .swiper-button-next, .swiper-button-prev {
        position: relative;
    }
    .swiper-button-next {
        right: -40px;
    }
    .swiper-slide {
        border-radius: 16px;
    }
    .swiper-wrapper {
        margin-top: 24px;
        margin-left: 8px;
    }
}


@include forTabletPortraitUp {
    .mySwiper {

        .swiper-slide {
            width: 48% !important;
        }

        .swiper-wrapper {
            margin-top: 0;
        }
    }
}

@include forDesktopUp {
    .mySwiper {
        .swiper-slide {
            margin-right: 16px !important;
            width: 48% !important;
        }
    }
}
