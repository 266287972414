@import "../../styles/common/mixins";
@import "../../styles/common/colors";
@import "../../styles/common/buttons";
@import "../../styles/common/card";

.dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.dashboard-offers-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  & > * {
    width: 100%;
  }
}
.opportunities-content {
  color: $grey600;
  margin-top: -10px;
  font-size: 14px;
}
.opportunities {
  padding-bottom: 70px;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: $primary500 !important;
}
.opportunities-header-container {
  display: flex;
  justify-content: space-between;
}
.opportunities-title-container {
  width: 90%;
  padding: 8px 0 8px 8px;
}
.opportunities-title {
  margin-bottom: 8px;
  &-opportunities {
    margin-top: 40px;
  }
  &-top {
    margin-top: 12px;
  }
}

.opportunities-slider-btn {
  margin-top: 10px;
}
.opportunities-title-arrows {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  .swiper-button-prev {
    background-color: $primary500;
    border-radius: 50%; /* Changed from 50px */
    height: 40px;
    width: 40px; /* Added this line */
    color: $grey100;
  }
  .swiper-button-next {
    background-color: $primary500;
    border-radius: 50%; /* Changed from 50px */
    height: 40px;
    width: 40px; /* Added this line */
    color: $grey100;
  }
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 18px !important;
  }
}
.no-investments {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding-top: 32px;
  span {
    color: $grey600;
    font-weight: 600;
    text-align: center;
  }
}

.our-offers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-top-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 8px 0;
  .css-s18byi {
    box-shadow: none !important;
  }
  &-title {
    font-weight: 500 !important;
    color: $grey600;
  }
}

.slider-section-title {
  color: $secondary700 !important;
}

@include forPhoneOnly{
  .dashboard-top-container {
    display: flex;
    flex-direction: column;
  }
  .dashboard-card-container{
    display: flex;
    flex-direction: column;
    .dashboard-card{
      width: 100%;
    }
  }
  .dashboard-slider-content {
    align-items: flex-start;
    justify-content: flex-start;
  }
}


@include forTabletPortraitUp {
  .dashboard-top-container {
    gap: 24px;
  }
  .our-offers-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .dashboard-offers-container {
    & > * {
      width: 48%;
    }
  }
  .opportunities-title-arrows {
    margin-top: 48px;
  }
  .dashboard-slider-content {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

@include forTabletLandscapeUp {
  .dashboard-top-container {
    display: flex;
    flex-direction: row;
  }
  .dashboard-banner {
    width: 100%;
  }
  .dashboard-slider-content {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
@include forDesktopUp {
  .dashboard-container {
    padding: 0 0 16px 32px;
  }
  .dashboard-offers-container {
    justify-content: space-between;
    & > * {
      width: 32%;
    }
  }
  .dashboard-top-container {
    display: flex;
    justify-content: space-between;
  }
  .opportunities {
    padding-bottom: 10px;
  }
  .opportunities-content {
    font-size: 16px;
  }
  .opportunities-title-container {
    width: 100%;
  }
}

