@import "../../../styles/common/buttons";
@import "../../../styles/common/mixins";

.complete-infos__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center; & > * {
    width: 100%;
  }
}

.complete-infos__inputs {
  margin: 10px 0;
  & > * {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.signup-email {
  width: 100% !important;
}

.address__inputs {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  & > * {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.submit-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;
}

.submit-container-opportunity {
  position: absolute;
  bottom: 20px;
  right: 24px;
  width: 20%;
}

.signup-submit {
  display: inline-block;
  width: 100%;
}

.submit-btn {
  @include btnLargeMainColored($primary500, $primary600);
  border: none;
  span {
    width: 20px !important;
    height: 20px !important;
  }
}

@include forTabletLandscapeUp {
  .complete-infos__inputs {
    display: flex;
    justify-content: space-between;
    & > * {
      width: 45%;
    }
  }
}
