@import "../../styles/common/colors";
@import "../../styles/common/mixins";

.arrow-container {
  border-radius: 100%;
  padding: 5px 5px;
  height: 27px;
  max-width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  svg{
    width: 100%;
    height: 100% ;
    object-fit: cover;
  }
  &-red {
   color: $error600;
  }
  &-green {
   color: $success600;
  }
  &-info {
   color: $info600;
  }
}

.see-doc-btn {
  color: $info600 !important;
  font-weight: 600 !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  svg {
    width: 20px;
  }
}

.see-more-btn {
  color: $info600 !important;
  font-weight: 600 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    width: 20px;
  }
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium {
  padding: 8px 16px !important;
  font-size: 0.750rem !important;
}

.MuiTableCell-root {
  cursor: default;
  color: $primary500 !important;
  padding: 2px 16px !important;
  border-bottom: none !important;
}

.ds_category_name {
  font-size: 11px !important;
  color: $grey800;
  font-weight: 500;
  a {
    color: $info500;
    font-weight: 600;
    text-decoration: none;
  }
}
 .boosts-status{
   display: flex;
   align-items: center;
   justify-content: space-between;
 }

 .disabled {
   opacity: 0.4;
 }

 .change-company-btn {
   color: $primary500;
   font-weight: 600;
   display: flex;
   gap: 4px;
   align-items: center;
   cursor: pointer;
 }

 .table-btns {
   flex-direction: row !important;
   margin-left: -16px;
   justify-content: flex-end;
   max-width: 175px;
 }

 .modal-btns-container {
   justify-content: flex-end;
   align-items: center;
 }

.table-row:nth-child(odd) {
  background-color: #ffffff;
}

.table-row:nth-child(even) {
  background-color: #f9f9f9;
}

.table-row {
  &.expanded {
    .MuiCollapse-root {
      background-color: inherit;
    }
  }
}

.funds-calls-btn {
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px
}

.funds-call-row {
  background-color: inherit;
  padding: 4px 16px 34px 16px !important;
}

.collapsed-row {
  background-color: inherit;
}

@include forTabletLandscapeUp {
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeMedium {
    //max-width: 230px !important;
    font-size: 0.9rem !important;
  }
  .ds_category_name {
    font-size: 14px !important;
  }
  .MuiTableCell-root {
    padding: 4px 16px;
  }
}
