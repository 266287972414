@import '../../styles/common/card';
@import '../../styles/common/colors';
@import '../../styles/common/mixins';

.os-tab {
  @include card;
  align-items: flex-start;
  flex-direction: column !important;
  height: auto;
  box-shadow: none !important;
  border: 1px solid $grey400;
  width: 100%;
  border-radius: 2px;
  gap: 8px;
  cursor: pointer;
  text-align: left;
  p {
    color: $grey700;
  }
  &:hover {
    border: 1px solid $primary600;
    transition: 0.7ms;
  }
  img {
    width: 15%;
  }
  &--selected {
    border: 1px solid $primary600;
    background-color: $secondary300;
    transition: 0.7ms;
  }
  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      border: 1px solid $grey400;
    }
  }
  &--full {
    width: 100%;
  }
}

.title-container {
  flex-direction: row !important;
  gap: 8px;
  align-items: center;
  color: $primary500;
}

.paiment-choice-container {
  margin-top: 32px;
}

@include forTabletPortraitUp {
  .os-tab {
    width: 48.5%;
    &--full {
      width: 100% ;
    }
  }
}
