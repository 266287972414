@import '../../../styles/common/colors';
@import '../../../styles/common/buttons';
@import '../../../styles/common/mixins';

.dashboard-banner {
  display: none;
}

.dashboard-banner-greetings {
  color: $primary500;
  font-size: 24px;
  margin: 0;
}

.only-phone {
  display: block;
}

.dashboard-banner-content {
  color: $primary500;
  max-width: 80%;
  margin-bottom: 24px;
  font-size: 13px;
}

.dashboard-banner-btn {
  @include btnLargeSecondaryColored($primary500, $grey400);
  font-size: 14px;
  font-weight: bold;
}

@include forTabletPortraitUp() {
  .dashboard-banner {
    display: none;
  }
  .only-phone {
    display: none;
  }
}

@include forTabletLandscapeUp {
  .dashboard-banner {
    background: $primaryLightGradient;
    padding: 40px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 65%;
  }
}

