@import "./colors";
@import "./shadows";
@import "./mixins";

%btn {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  border-radius: 2px;
  gap: 4px;
  font-weight: 600;
  font-size: 12px ;
  width: fit-content;
  border: none;
  &:disabled {
    cursor: initial;
    color: $disabled;
  }
  svg {
    width: 18px !important;
    height: 18px !important;
  }
}

@mixin btnMain($color, $colorHover) {
  @extend %btn;
  color: $white;
  background-color: $color;

  &:hover, &:active {
    background-color: $colorHover;
    transition: 0.5s;
  }
}

@mixin btnLargeMainColored($color, $colorHover) {
  @include btnMain($color, $colorHover);
  padding: 11px 22px;
  font-size: 15px;
  svg {
    width: 24px;
  }
}

@mixin btnMainColored($color, $colorHover) {
  @include btnMain($color, $colorHover);
  padding: 8px 16px;
  font-size: 14px;
  svg {
    width: 20px;
  }
}

@mixin btnSmallMainColored($color, $colorHover) {
  @include btnMain($color, $colorHover);
  padding: 7px 10px;
  font-size: 13px;
  svg {
    width: 18px;
  }
}

@mixin btnMainSecondary() {
  @extend %btn;
  color: $secondary800;
  background-color: $secondary400;
  border: none;
  &:hover, &:active {
    background-color: $secondary600;
    transition: 0.2s;
  }
}

@mixin btnLargeMainSecondaryColored {
  @include btnMainSecondary;
  padding: 11px 22px;
  font-size: 15px
}

@mixin btnMainSecondaryColored {
  @include btnMainSecondary;
  padding: 8px 16px;
  font-size: 14px
}

@mixin btnSmallMainSecondaryColored {
  @include btnMainSecondary;
  padding: 7px 10px;
  font-size: 13px
}


@mixin btnSecondary($color, $colorHover) {
  @extend %btn;
  color: $color;
  background-color: transparent;
  border: 1px solid $color;
  &:hover, &:active {
    background-color: $colorHover;
    color: $color;
    transition: 0.5s;
  }
}

@mixin btnLargeSecondaryColored($color, $colorHover) {
  @include btnSecondary($color, $colorHover);
  padding: 11px 22px;
  font-size: 14px
}

@mixin btnSecondaryColored($color, $colorHover) {
  @include btnSecondary($color, $colorHover);
  padding: 8px 16px;
  font-size: 13px
}

@mixin btnSmallSecondaryColored($color, $colorHover) {
  @include btnSecondary($color, $colorHover);
  padding: 7px 10px;
  font-size: 12px
}

@mixin btnNoBorder($color, $colorHover) {
  @extend %btn;
  color: $color;
  background-color: transparent;
  border: none;
  &:hover, &:active {
    color: $colorHover;
    transition: 0.5s;
  }
}

@mixin btnLargeNoBorder($color, $colorHover) {
  @include btnNoBorder($color, $colorHover);
  font-size: 15px
}

@mixin btnMediumNoBorder($color, $colorHover) {
  @include btnNoBorder($color, $colorHover);
  font-size: 14px
}

@mixin btnSmallNoBorder($color, $colorHover) {
  @include btnNoBorder($color, $colorHover);
  font-size: 13px
}


%fab{
  @include z24;
  border-radius: 100%;
  color: $white;
  text-decoration: none;
  display: flex;
}

@mixin fabLargeMainColored($color) {
  @extend %fab;
  width: 56px;
  height: 56px;
  background: $color;
  padding: 24px;
}

@mixin fabMainColored($color) {
  @extend %fab;
  width: 48px;
  height: 48px;
  background: $color;
  padding: 16px;
}

@mixin fabSmallMainColored($color) {
  @extend %fab;
  width: 40px;
  height: 40px;
  background: $color;
  padding: 8px;
}

@mixin fabNoBorder($color) {
  @extend %fab;
  color: $color;
  background-color: transparent;
  border: none;
}


@include forDesktopUp {
  %btn {
    font-size: 14px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
