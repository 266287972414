@import '../../../styles/common/mixins';

.cgp-top-container {
  width: 100%;

  & > * {
    width: 100% !important;
  }
  .product-document {
    width: 100%;
  }
}

.profile-info-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.collaborator-uploads {
  flex-wrap: wrap;
}

@include forTabletLandscapeUp {
  .cgp-infos-container {
    justify-content: space-between;
    & > * {
      width: 70%;
    }
  }
  .cgp-top-container {
    & > * {
      width: 100% !important;
    }
    .product-document {
      width: 32%;
    }
  }
}

