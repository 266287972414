@import "../../../styles/common/mixins";
@import "../../../styles/common/colors";

p {
  color: $grey600;
}

.dashboard-container {
  padding: 16px 12px 0;
}

.dashboard-bottom-container {
  position: relative;
}


.dashboard-container-title {
  align-items: center;
  justify-content: space-between;
}

.dashboard-top-container-btns {
  flex-direction: row !important;
  align-items: center;
  gap: 16px;
}

.ressources-container {
  & > * {
    width: 100%;
  }
  margin-bottom: 32px;
}

.login-title{
  color: $primary400;
}

.ressources-img {
  width: 100% ;
  border-radius: 2px;
}

.dashboard-cards {
  width: 100%;
  flex-direction: row !important;
  overflow: auto;
  white-space: nowrap;
  padding: 4px 0 24px 4px;
  gap: 16px;
  & > * {
    width: 100%;
  }
}
.card-icon-container {
  align-items: center;
  flex-direction: row !important;
}

.card-icon-title {
  align-items: center;
  flex-direction: row !important;
  gap: 4px;
  svg {
    cursor: pointer;
    color: $grey600;
    width: 20px;
    height: 20px;
  }
}

.data-card {
  color: $grey700;
}

@include forTabletLandscapeUp {
  .dashboard-container{
    padding: 16px 24px 0;
  }

  .ressources-container {
    flex-direction: row !important;
    & > * {
      width: 31%;
    }
  }

  .dashboard-cards {
    white-space: wrap;
    & > * {
      width: 32.2%;
    }
  }
}
