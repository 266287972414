@import '../../../styles/common/mixins';

.companies-container {
  max-width: 96%;
  margin: 32px auto auto auto;
}

.companies-title-container {
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
}

@include forTabletPortraitUp {
  .companies-title-container {
    flex-direction: row !important;
    align-items: center;
  }
}

