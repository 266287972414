@import '../../../styles/common/mixins';
@import '../../../styles/common/colors';
@import '../../../styles/common/buttons';
@import '../../../styles/common/card';

.withdraw-container {
  @include card;
  height: 100%;
  width: 90%;
  margin: 20px auto;
}

.withdraw-amount {
  margin: 20px 0;
}

.withdraw-available {
  margin: 20px 0;
  p {
    color: $grey500;
  }
  span {
    color: $navy;
  }
}

.withdraw-notice {
  font-size: 11px;
  font-style: italic;
  width: 80%;
}

.withdraw-informations {
  p {
    color: $navy;
  }
}
.withdraw-otp {
  width: 100%;
  margin: 20px 0;
}


@include forTabletLandscapeUp {
  .withdraw-container {
    width: 70%;
  }
}

@include forDesktopUp {
  .withdraw-container {
    width: 50%;
  }
}
