@import "../../styles/common/mixins";
@import "../../styles/common/colors";

.callout-livretp {
  border-radius: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  color: $info500;
  p {
    color: $info500 !important;
  }
}


