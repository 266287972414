@import '../../styles/common/colors';
@import '../../styles/common/buttons';
@import '../../styles/common/shadows';

.os-button {
  width: 100%;
  position: relative;
  height: fit-content;
  &-fit {
    width: fit-content;
  }
  &-primary {
    &__large {
      @include btnLargeMainColored($primary600, $primary800);
    }
    &__medium {
      @include btnMainColored($primary600, $primary800);
    }
    &__small {
      @include btnSmallMainColored($primary600, $primary800)
    }
    &-full {
      &__large {
        @include btnLargeMainSecondaryColored;
      }
      &__medium {
        @include btnMainSecondaryColored;
      }
      &__small {
        @include btnSmallMainSecondaryColored;
      }
    }
    &-warning {
      &__large {
        @include btnLargeMainColored($warning500, $warning600);
      }
      &__medium {
        @include btnMainColored($warning500, $warning600);
      }
      &__small {
        @include btnSmallMainColored($warning500, $warning600)
      }
    }
  }
  &-secondary-BlueInfo {
    &__large {
      @include btnLargeSecondaryColored($info600, $grey300);
    }
    &__medium {
      @include btnLargeSecondaryColored($info600, $grey300);
    }
    &__small {
      @include btnLargeSecondaryColored($info600, $grey300)
    }
  }
  &-secondary {
    &__large {
      @include btnLargeSecondaryColored($primary500, $grey400);
    }
    &__medium {
      @include btnLargeSecondaryColored($primary500, $grey400);
    }
    &__small {
      @include btnSmallSecondaryColored($primary500, $grey400);
    }
    &-white {
      &__large {
        @include btnLargeSecondaryColored($white, $grey400);
      }
      &__medium {
        @include btnLargeSecondaryColored($white, $grey400);
      }
      &__small {
        @include btnSmallSecondaryColored($white, $grey400);
      }
    }
  }
  &-no_border {
    @include btnNoBorder($primary500, $primary400);
    &__large {
      @include btnLargeNoBorder($primary500, $primary400);
      padding: 11px 22px;
    }
    &__medium {
      @include btnMediumNoBorder($primary500, $primary400);
    }
    &__small {
      @include btnSmallNoBorder($primary500, $primary400);
    }
    &-info {
      @include btnNoBorder($secondary700, $secondary900);
      &__large {
        @include btnLargeNoBorder($secondary700, $secondary900);
      }
      &__medium {
        @include btnMediumNoBorder($secondary700, $secondary900);
      }
      &__small {
        @include btnSmallNoBorder($secondary700, $secondary900);
      }
    }
  }
  &-coming {
    @include btnMainColored($info500, $info600);
    color: $info600;
    &:hover {
      color: $info500;
      cursor: not-allowed;
    }
  }
}

.btn-submitting {
  align-items: center;
}

.dropdown-button {
  position: relative;
  cursor: pointer;
  align-items: center;
  flex-direction: row !important;
}

.dropdown-menu {
  position: absolute;
  text-align: left;
  left: 0;
  top: 100%;
  z-index: 1;
  display: none;
  width: 100%;
  padding: 16px 0 8px 0;
  margin-top: 2px;
  list-style: none;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  @include cardShadow;
  li {
    font-size: 13px;
    padding: 8px 16px;
    color: $primary500;
    font-weight: 400;
    &:hover {
      background-color: $grey200;
    }
  }
}

.dropdown-button .dropdown-menu {
  display: block;
}

.coming-soon-container {
  position: absolute;
  top: -15px;
  right: 0;
}
