@import "../../../../src/styles/common/card";
@import "../../../../src/styles/common/colors";
@import "../../../../src/styles/common/mixins";

.promo-card {
  @include card;
  height: auto;
  width: 100%;
  &--used {
    background-color: $grey200;
  }
  &--ad {
    border: 1px solid $primary500;
  }
  &-top {
    justify-content: space-between;
    align-items: center;
    flex-direction: row !important;
    svg {
      color: $success500;
    }
  }
  img {
    width: 30px;
    height: 30px;
  }
}

.promo-description {
  word-break: break-word;
  min-height: 70px;
  color: $grey600;
  white-space: pre-wrap;
  min-width: 230px;
}

.promo-card-copy {
  border-radius: 5px;
  color: $primary500;
  font-weight: 600;
  margin: 24px 0;
}

.promo-expire {
  color: $warning700;
  flex-direction: row !important;
}
.promo-used {
  color: $success500;
  flex-direction: row !important;
}

.promotions-list {
  flex-wrap: nowrap;
  flex-direction: row !important;
  overflow: auto;
  white-space: nowrap;
}

.small-promo-card {
  border: 1px solid $grey300;
  border-radius: 2px;
  padding: 0 16px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  &:hover {
    transition: 0.7ms;
    border: 1px solid $primary500;
  }
  &--selected  {
    border: 1px solid $success500;
  }
}

@include forTabletLandscapeUp {
  .promo-card {
    width: 31%;
  }
  .promotions-list {
    flex-wrap: wrap;
  }
}
