@import '../../../styles/common/buttons';
@import '../../../styles/common/mixins';

.update-amount-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .update-amount-input {
    width: 82%;
  }
  .update-amount-btn-container {
    width: 15%;
  }
  .update-amount-btn {
    @include btnLargeMainColored($primary500, $primary600);
    &-secondary {
      @include btnSecondaryColored($primary500, $grey400);
    }
  }
}

.colored-container {
  background: $grey200;
  padding: 12px;
  margin: 0 0 16px 0;
  border-radius: 4px;
}

.info {
  color: $info400 !important;
  margin-top: 5px;
  margin-left: 2px;
}

@include forTabletLandscapeUp {
  .update-amount-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
