@import '../../styles/common/mixins';
@import '../../styles/common/colors';
@import '../../styles/common/buttons';
@import '../../styles/common/mixins';
@import '../../styles/common/card';

.modal-identity {
  display: flex;
  flex-direction: column;
  &__img {
    width: 70%;
    margin: auto auto 24px auto;
  }
  &__buttons {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin-top: 24px;
    gap: 24px;
  }
  &__content {
    color: $grey500;
  }
}

.blue-button {
  @include btnLargeMainColored($primary500, $primary600);
}

.cancel-button {
  @include btnLargeMainColored($grey500, $grey600);
}

@include forTabletLandscapeUp {
  .modal-identity {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__img {
      width: 35%;
      margin: 0;
    }
    &__buttons {
      width: 100%;
    }
  }
}
