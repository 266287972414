  @import "../../../styles/common/mixins";
@import "../../../styles/common/colors";
@import "../../../styles/common/card";

.dashboard-card {
  @include card;
  height: auto;
  display: flex;
  flex-direction: column;
  .dashboard-card-title{
    color: $grey800;
  }
  svg {
    color: $grey600;
  }
}

.dashboard-card-top-container {
  align-items: flex-start;
}

.dashboard-card-content {
  color: $grey600;
  margin-top: 0;
  margin-bottom: 10px;
  flex-grow: 1;
}

@include forTabletLandscapeUp {
  .dashboard-card {
    width: 33%;
  }
  .dashboard-card-top-container {
    align-items: center;
  }
}
