@import '../../../styles/common/card';
@import '../../../styles/common/mixins';
@import '../../../styles/common/buttons';

.question-container {
  @include card;
  width: 90%;
  margin: 10px auto 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.question-title {
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid $grey400;
}

.answers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.answer-card {
  width: 100%;
  border-radius: 10px;
  border: 2px solid $primary500;
  color: $primary500;
  padding: 0 8px;
  cursor: pointer;
  transition: 0.6s;
  display: flex;
  align-items: center;
  gap: 20px;
  &:hover {
    background: $primary500;
    color: $white;
    transition: 0.6s;
  }
  &-selected {
    background: $primary500;
    color: $white;
  }
}
.answer-letter {
  border-radius: 5px;
  border: 2px solid $primary500;
  background-color: $primary500;
  padding: 2px 8px;
  color: $white;
}

.answer-content {
  font-size: 14px;
}
.question-submit {
  margin: 50px 0;
}

.question-illustration {
  width: 300px !important;
  height: 240px !important;
  margin: auto;
}
.question-joke {
  color: $grey700;
  font-style: italic;
}
.question-text {
  font-size: 13px;
  margin: 7px 0;
  color: $grey600;
}

.question-response-container {
  width: 80%;
}

.question-submit-btn {
  @include btnLargeMainColored($primary500, $primary600)
}

@include forTabletPortraitUp() {
  .question-container {
    width: 50%;
    margin: 10px auto 50px auto;
  }

  .answers {
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
  }

  .answer-card {
    width: 100%;
  }
  .answer-card-full {
    width: 100%;
    padding-left: 16px;
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
      color: $primary500;
    }
  }
}
