@import '../../styles/common/colors';
@import '../../styles/common/mixins';
@import '../../styles/common/buttons';

.transactions-page {
  width: 94%;
  margin: 40px auto auto auto;
  .MuiInputLabel-outlined {
    background-color: transparent !important;
  }
  color: $grey700;
}

.pagination-container {
  background-color: $secondary200;
  padding: 8px 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 2px;
  align-content: center;
}
.filters-container {
  padding: 8px 0 24px 0;
  margin-bottom: 24px;
  align-content: center;
}

.filters-btns {
  display: flex;
  gap: 16px;
  overflow: auto;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.transactions-filters {
  margin: 24px 0 0 0;
}

.pagination-container {
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
}

.filter-btn {
  @include btnLargeNoBorder($secondary800, $secondary600);
  padding: 12px;
}
