@import "../../styles/common/colors";
$assets-img-path: "../../assets/images";


.os-block {
  background-color: $secondary400;
  padding: 16px 24px 16px 24px;
  border-radius: 2px;
  min-height: 250px;
  height: fit-content;
  color: $secondary900 !important;
  p {
    font-size: 14px !important;
  }
  ul {
    padding: 0;
  }
  li {
    background: url('#{$assets-img-path}/ArrowRightFullIcon.svg') no-repeat left top;
    padding-left: 24px;
    list-style: none;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
}
