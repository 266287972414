@import '../../../../styles/common/colors';
@import '../../../../styles/common/mixins';

.card-container {
  width: 100%;
  .kr-embedded {
    width: 100%;
  }
  .kr-payment-button {
    background-color: $primary500 !important;
    border-color: $primary500 !important;
    font-size: 16px;
    font-weight: 700;
  }
  .card-title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    h3 {
      width: 100%;
    }
  }
  .secured-img {
    max-width: 40%;
    height: 50px;
  }
}

@include forTabletPortraitUp {
  .card-title-container {
    gap: 16px;
    h3 {
      width: 50%;
    }
  }
  .secured-img {
    max-width: 50%;
  }
}
